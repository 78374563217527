import { useUndoableContext } from 'contexts/undoable';
import { useCallback } from 'react';
import { applyNodeChanges, NodeProps } from 'react-flow-renderer';

export function useRemoveNode(id: NodeProps['id']) {
  const { setElements } = useUndoableContext();

  const onClick = useCallback(() => {
    setElements((elements) => ({
      ...elements,
      edges: elements.edges?.filter(
        (edge) => edge.source !== id && edge.target !== id
      ),
      nodes: elements.nodes
        ? applyNodeChanges([{ id: id, type: 'remove' }], elements.nodes)
        : elements.nodes,
    }));
  }, [id, setElements]);

  return onClick;
}

export default useRemoveNode;
