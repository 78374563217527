import './style.scss';

import { MouseEvent as ReactMouseEvent, useEffect, useRef } from 'react';

import { LeftResizableProps } from './types';

const SIDEBAR_LOCALSTORAGE_WIDTH_NAME = 'SIDEBAR_LOCALSTORAGE_WIDTH_NAME';

function LeftResizable({ sidebarRef }: LeftResizableProps) {
  const initPos = useRef<number>(0);
  const initSize = useRef<number>(0);

  const resize = (event: MouseEvent) => {
    event.preventDefault();
    if (sidebarRef.current) {
      const newWidth = initSize.current + initPos.current - event.clientX;
      sidebarRef.current.style.width = `${newWidth}px`;
      localStorage.setItem(
        SIDEBAR_LOCALSTORAGE_WIDTH_NAME,
        newWidth.toString()
      );
    }
  };

  const onMouseDown = (event: ReactMouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    initPos.current = event.clientX;
    if (sidebarRef.current) initSize.current = sidebarRef.current.offsetWidth;
    window.addEventListener('mousemove', resize);
  };

  const onMouseUp = (event: ReactMouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    window.removeEventListener('mousemove', resize);
  };

  useEffect(() => {
    if (sidebarRef.current) {
      const savedWidth =
        localStorage.getItem(SIDEBAR_LOCALSTORAGE_WIDTH_NAME) ?? 450;
      sidebarRef.current.style.width = `${savedWidth}px`;
    }
  }, [sidebarRef]);

  return (
    <div
      className='resizable__left'
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      role='presentation'
    ></div>
  );
}

export default LeftResizable;
