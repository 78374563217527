import cn from 'classnames';
import { Link } from 'react-router-dom';

import { ProfileMenuItemProps } from './ProfileMenuItem.props';

const ProfileMenuItem = (props: ProfileMenuItemProps) => {
  const { path, text, icon, appearance } = props;
  const classItemBase = `bx ${icon} font-size-16 align-middle me-1`;

  return (
    <>
      <Link to={path} className='dropdown-item'>
        <i
          className={cn(classItemBase, {
            'text-danger': appearance === 'danger',
          })}
        />
        <span>{text}</span>
      </Link>
    </>
  );
};

export default ProfileMenuItem;
