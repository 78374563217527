import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import UserService from './service';

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_: void, thunkAPI) => {
    try {
      const response = await UserService.fetchUser();

      return response.data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          return thunkAPI.rejectWithValue(error.response.data.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue('Неизвестная проблема');
    }
  }
);
