import { TableWithPagination } from 'modules';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUsers, setPagination } from 'store/users/actions';

import { getTableUsersColumns } from './columns';

const TableUsers = () => {
  const { isLoadingUsers, users, tableConfig } = useAppSelector(
    (state) => state.users
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  if (isLoadingUsers) {
    return <Spinner animation='border' variant='success' />;
  }

  return (
    <TableWithPagination
      keyField='id_user'
      data={users as unknown as Record<string, unknown>[]}
      getColumns={getTableUsersColumns}
      tableConfig={tableConfig}
      dispatchAction={setPagination}
    />
  );
};

export default TableUsers;
