import './style.scss';

import { useCallback, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setStatus } from 'store/statusApp/actions';

const ActionStatusMessage = () => {
  const dispatch = useAppDispatch();
  const { status, message } = useAppSelector((state) => ({
    status: state.statusApp.status,
    message: state.statusApp.message,
  }));

  const onCloseAlert = useCallback(() => {
    dispatch(setStatus({ status: 'none', message: '' }));
  }, [dispatch]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (status !== 'none') {
      timer = setTimeout(() => {
        onCloseAlert();
      }, 8000);
    }

    return () => {
      if (status !== 'none') {
        onCloseAlert();
        clearTimeout(timer);
      }
    };
  }, [onCloseAlert, status]);

  return (
    <div id='toast-container' className='alert__conteiner'>
      <Alert
        show={status !== 'none'}
        variant={status}
        onClose={onCloseAlert}
        dismissible
        className='alert__content'
      >
        {message}
      </Alert>
    </div>
  );
};

export default ActionStatusMessage;
