import './styles.scss';

import Alert from 'react-bootstrap/Alert';
import { useAppSelector } from 'store/hooks';

const ErrorMessage = () => {
  const { error } = useAppSelector((state) => state.auth);

  return error ? (
    <Alert key='danger' variant='danger' className='errorMessageAuth'>
      {error}
    </Alert>
  ) : (
    <></>
  );
};

export default ErrorMessage;
