import { ControlsInTable } from 'components';
import Dropdown from 'react-bootstrap/Dropdown';
import { useAppDispatch } from 'store/hooks';
import { setModal } from 'store/users/actions';

import { ControlsProps } from './types';

const Controls = ({ userId }: ControlsProps) => {
  const dispatch = useAppDispatch();

  const onEdit = () => {
    dispatch(setModal({ type: 'edit', targetId: userId }));
  };

  const onDelete = () => {
    dispatch(setModal({ type: 'delete', targetId: userId }));
  };

  return (
    <ControlsInTable>
      <Dropdown.Item className='dropdown-item' onClick={onEdit}>
        Редактировать
      </Dropdown.Item>
      <Dropdown.Item className='dropdown-item primary-color' onClick={onDelete}>
        Удалить
      </Dropdown.Item>
    </ControlsInTable>
  );
};

export default Controls;
