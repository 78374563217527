import './style.scss';

import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';

interface TrashProps extends HTMLAttributes<SVGElement> {
  pathProps?: HTMLAttributes<SVGPathElement>;
}
export const TrashSvg: FC<TrashProps> = ({ pathProps = {}, ...svgProps }) => (
  <svg
    width='14'
    height='16'
    viewBox='0 0 14 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
    className={classNames('trash__icon', svgProps.className)}
  >
    <path
      d='M1.01187 15.5V2.87154H0.00396729V1.29051H4.19369V0.5H9.78658V1.29051H13.9961V2.87154H12.9882V15.5H1.01187ZM2.57314 13.9387H11.4269V2.87154H2.57314V13.9387ZM4.62847 12.3182H5.99211V4.43281H4.62847V12.3182ZM8.00792 12.3182H9.39132V4.43281H8.00792V12.3182ZM2.57314 2.87154V13.9387V2.87154Z'
      {...pathProps}
      className={classNames('trash__icon_path', pathProps.className)}
    />
  </svg>
);

export const PlusSvg: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11.8333 6.83268H6.83329V11.8327H5.16663V6.83268H0.166626V5.16602H5.16663V0.166016H6.83329V5.16602H11.8333V6.83268Z'
      fill='#34C38F'
    />
  </svg>
);

export const ActionSvg: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.72277 14.3564L4.65347 13.2871L7.48267 10.4356H1V8.92079H7.48267L4.65347 6.06931L5.72277 5L10.401 9.67822L5.72277 14.3564ZM11.3589 14.0891V12.5743H19V14.0891H11.3589ZM11.3589 6.78218V5.26733H19V6.78218H11.3589ZM14.0322 10.4356V8.92079H19V10.4356H14.0322Z'
      fill='currentColor'
    />
  </svg>
);

export const VariableSvg = () => (
  <svg
    width='20'
    height='16'
    viewBox='0 0 20 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.02531 10.7568L5.08869 11.8919L8.78518 7.94595L5.08869 4L4 5.13513L6.63311 7.94595L4.02531 10.7568ZM9.89919 10.3784V12H15.4693V10.3784H9.89919Z'
      fill='currentColor'
    />
  </svg>
);
