import { TableWithPagination } from 'modules';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchRoles, setPagination } from 'store/roles/actions';

import { getTableRolesColumns } from './columns';

const TableRoles = () => {
  const { isLoadingRoles, roles, tableConfig } = useAppSelector(
    (state) => state.roles
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  if (isLoadingRoles) {
    return <Spinner animation='border' variant='success' />;
  }

  return (
    <TableWithPagination
      keyField='id_role'
      data={roles as unknown as Record<string, unknown>[]}
      getColumns={getTableRolesColumns}
      tableConfig={tableConfig}
      dispatchAction={setPagination}
    />
  );
};

export default TableRoles;
