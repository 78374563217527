import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { nameApp } from 'constants/app_constants';

import { fetchEditorScript, setEditorScriptId } from './actions';
import { AllCrmFields, EditorState, InitialScript } from './types';

const sessionStore = JSON.parse(sessionStorage.getItem(nameApp) || '{}');

const initialState: EditorState = {
  isLoadingScript: false,
  selectedEditorScriptId: sessionStore?.editor?.selectedEditorScriptId,
  crmFields: {},
};

export const scriptsState = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setInitialScript(state, { payload }: PayloadAction<InitialScript>) {
      state.initialScript = payload;
    },
    setCrmFields(state, { payload }: PayloadAction<AllCrmFields>) {
      state.crmFields = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditorScript.pending, (state) => {
        state.isLoadingScript = true;
      })
      .addCase(fetchEditorScript.fulfilled, (state, action) => {
        state.initialScript = action.payload;
        state.isLoadingScript = false;
      })
      .addCase(setEditorScriptId, (state, action) => {
        state.selectedEditorScriptId = action.payload.scriptId;
      });
  },
});

export default scriptsState.reducer;

export const { setInitialScript, setCrmFields } = scriptsState.actions;
