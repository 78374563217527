import './style.scss';

import { Button } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { setModal } from 'store/users/actions';

const ButtonCreateUser = () => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(setModal({ type: 'create', targetId: -1 }));
  };

  return (
    <Button onClick={onClick} className='buttonCreateUser'>
      Создать пользователя
    </Button>
  );
};

export default ButtonCreateUser;
