import ActionStatusMessage from 'modules/ActionStatusMessage';

import { Content, Footer, Header } from './components';
import { LayoutProps } from './types';

export const Layout = ({ children }: LayoutProps): JSX.Element => {
  return (
    <div id='layout-wrapper'>
      <Header />
      <Content>{children}</Content>
      <ActionStatusMessage />
      <Footer author='2022 © ' tagline='' />
    </div>
  );
};
