import { AxiosResponse } from 'axios';
import { ENDPOINTS } from 'constants/api_endpoints';
import { IResponse } from 'models/response';
import axiosApi from 'utils/api_helper';

import { AuthLoginConfig, AuthLoginParams } from './types';

export default class AuthService {
  static async login(data: AuthLoginParams) {
    const response: AxiosResponse<IResponse<AuthLoginConfig>> =
      await axiosApi.post(ENDPOINTS.AUTH_LOGIN, data);

    return response;
  }

  static async logout() {
    const response: AxiosResponse<IResponse<''>> = await axiosApi.get(
      ENDPOINTS.AUTH_LOGOUT
    );

    return response;
  }
}
