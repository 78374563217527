// @ts-nocheck
import { nameApp, REQUIRED_STATUS_FIELDS } from 'constants/app_constants';

import type { RootState } from './store';

export default class StateLoader {
  loadState() {
    try {
      const serializedState = sessionStorage.getItem(nameApp);

      if (serializedState === null) {
        return this.initializeState();
      }

      return JSON.parse(serializedState);
    } catch (err) {
      return this.initializeState();
    }
  }

  saveState(state: RootState) {
    try {
      const needObj = {};
      Object.keys(REQUIRED_STATUS_FIELDS).forEach((key) => {
        needObj[key] = {};
        REQUIRED_STATUS_FIELDS[key].forEach(
          (field: string) => (needObj[key][field] = state[key][field])
        );
      });

      const serializedState = JSON.stringify(needObj);
      sessionStorage.setItem(nameApp, serializedState);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  initializeState() {
    return {};
  }
}
