import { useUndoableContext } from 'contexts/undoable';
import { useEffect, useState } from 'react';
import { ReactFlowState, useStore } from 'react-flow-renderer';

const nodesLengthSelector = (state: ReactFlowState) =>
  Array.from(state.nodeInternals.values()).length || 0;

export function useOptions(nodeId?: string) {
  const [options, setOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const nodesLength = useStore(nodesLengthSelector);
  const { elements } = useUndoableContext();

  useEffect(() => {
    if (elements.nodes) {
      const defaultOptions = elements.nodes
        .filter((node) => node.id !== nodeId)
        .map((node) => ({
          value: node.id,
          label: node.data.label,
        }));

      setOptions(defaultOptions);
    }
  }, [elements.nodes, nodeId, nodesLength]);

  return { options, setOptions };
}

export default useOptions;
