import { UndoableState } from 'contexts/undoable';
import { CrmEntity, CrmEntityFields } from 'store/editor/types';

export const getAllKeysFromEntities = (
  allCrmEntities: [string, CrmEntityFields][]
): [
  `${CrmEntityFields['crm_name']}${CrmEntity['module_name']}`,
  CrmEntity['key']
][] => {
  const result: [CrmEntity['module_name'], CrmEntity['key']][] = [];
  for (const crm of allCrmEntities) {
    const { crm_name: _crm_name, ...crmEntities } = crm[1];
    for (const [key, entity] of Object.entries(crmEntities)) {
      result.push([`${crm[0]}:${key}`, entity.key]);
    }
  }
  return result;
};

export const getDifferenceInKeys = (
  newAllKeys: ReturnType<typeof getAllKeysFromEntities>,
  existingKeysObject: UndoableState['crmKeys'] = {}
) => {
  const existingKeys = Object.entries(existingKeysObject);
  const differenceInKeys = newAllKeys.filter(
    ([newEntityKey, newValueKey]) =>
      existingKeys.length === 0 ||
      !existingKeys.some(
        ([existingEntityKey, existingValueKey]) =>
          existingEntityKey === newEntityKey && existingValueKey === newValueKey
      )
  );
  return differenceInKeys;
};
