import ReactRelect from 'react-select';

import { colors } from './constants';
import { SelectProps } from './types';

function Select(props: SelectProps) {
  return (
    <ReactRelect
      noOptionsMessage={() => ''}
      theme={(theme) => ({
        ...theme,
        spacing: {
          baseUnit: 2,
          controlHeight: 34,
          menuGutter: 1,
        },
        colors: {
          ...theme.colors,
          ...colors,
        },
      })}
      {...props}
    />
  );
}

export default Select;
