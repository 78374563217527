import './style.scss';

import { useUndoableContext } from 'contexts/undoable';
import { useConnectedEdges } from 'hooks';
import { useMemo, useRef } from 'react';
import { ReactFlowState, useStore } from 'react-flow-renderer';

import { PanelAddEdge, PanelEdges, PanelNode } from './components';
import LeftResizable from './components/LeftResizable';
import { SidebarProps } from './types';

const edgesLengthSelector = (state: ReactFlowState) => state.edges.length;

function Sidebar(props: SidebarProps) {
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const { selectedNodeId, selectedEdgeId } = props;
  const { getEdgesByType } = useConnectedEdges();
  const { getSelectedEdge, getSelectedNode, getNode } = useUndoableContext();
  useStore(edgesLengthSelector);

  const selectedNode = useMemo(
    () =>
      selectedNodeId
        ? getSelectedNode()
        : getNode(getSelectedEdge()?.source || ''),
    [getNode, getSelectedEdge, getSelectedNode, selectedNodeId]
  );

  if (!selectedNode) return null;

  const edges = getEdgesByType(selectedNode);

  return (
    <div id='sidebar' className='sidebar' ref={sidebarRef}>
      <LeftResizable sidebarRef={sidebarRef} />
      <h4>Редактирование шага </h4>
      <div className='sidebar__body'>
        {selectedNode && <PanelNode node={selectedNode} />}
        <PanelAddEdge
          key={`outgoing__${selectedEdgeId}_add_node`}
          title='Исходящие ответы'
          typeTargetField='target'
          nodeParent={selectedNode}
        />
        <PanelEdges
          key={`outgoing__${selectedEdgeId}`}
          edges={edges.outgoing}
          typeTargetField='target'
          selectedEdgeId={selectedEdgeId}
        />
        <PanelAddEdge
          key={`incoming__${selectedEdgeId}_add_node`}
          title='Входящие ответы'
          typeTargetField='source'
          nodeParent={selectedNode}
        />
        <PanelEdges
          key={`incoming__${selectedEdgeId}`}
          edges={edges.incoming}
          typeTargetField='source'
          selectedEdgeId={selectedEdgeId}
        />
      </div>
    </div>
  );
}
export default Sidebar;
