import { useAppDispatch } from 'store/hooks';
import { setModal } from 'store/users/actions';

const useCloseModal = () => {
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    dispatch(setModal({ type: 'none' }));
  };

  return onCloseModal;
};

export default useCloseModal;
