import './style.scss';

import { BreadcrumbItem, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { BreadcrumbProps } from './types';

const Breadcrumbs = ({ breadcrumbItems, title }: BreadcrumbProps) => {
  return (
    <Row className='breadcrumbs'>
      <Col xs='12'>
        <div className='page-title-box'>
          <h4 className='mb-0 font-size-18 breadcrumbs__title'>{title}</h4>
          <div className='page-title-right mt-2'>
            <ol className='breadcrumb m-0'>
              {breadcrumbItems.map((item, index) => {
                const itemTitle = typeof item === 'string' ? item : item.title;
                const href = typeof item === 'string' ? undefined : item.to;
                return (
                  <BreadcrumbItem
                    key={`${itemTitle}_${index}`}
                    className='breadcrumbs__item'
                    active={index === breadcrumbItems.length - 1}
                    linkAs='span'
                  >
                    {href ? (
                      <NavLink to={href}>{itemTitle}</NavLink>
                    ) : (
                      itemTitle
                    )}
                  </BreadcrumbItem>
                );
              })}
              {/* <BreadcrumbItem className='breadcrumbs__item'>
                <span>{props.breadcrumbItems[0]}</span>
              </BreadcrumbItem>
              <BreadcrumbItem
                className='breadcrumbs__item breadcrumbs__item_active'
                active
              >
                <span className='text-secondary'>
                  {props.breadcrumbItems[1]}
                </span>
              </BreadcrumbItem> */}
              {}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumbs;
