import { CloseButton } from 'components';
import { ActionSvg } from 'components/Icons';
import { useFormik } from 'formik';
import { ChangeEvent, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import {
  addCustomActionToEditor,
  CKEDITOR_ACTION_COMMAND_NAME,
} from '../../helpers/editor-helpers';
import {
  CustomActionCommandValue,
  CustomActionProps,
  FormValues,
} from './types';
import { CustomActionValidation } from './validation';

const ACTION_TEMPLATE_CLASSNAME = 'ckeditor5__plugin_toolbar_template_action';

const initialValues: FormValues = {
  data: '',
  method: 'GET',
  title: '',
  url: '',
};

function CustomAction({ editor }: CustomActionProps) {
  const [isOpen, setIsOpen] = useState(false);

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
  } = useFormik<FormValues>({
    initialValues,
    validationSchema: CustomActionValidation,
    validateOnChange: false,
    onSubmit(data) {
      if (editor) {
        addCustomActionToEditor(editor, data);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onCloseModal();
      }
    },
  });

  const onCloseModal = () => {
    resetForm();
    setIsOpen(false);
  };

  const manuallyHandleChangeWithTriggerValidation = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => setFieldValue(event.target.name, event.target.value, true);

  const onOpenModal = () => {
    setIsOpen(true);
    if (editor) {
      // When some action selected in editor
      const valueIfActionSelected =
        (editor.commands.get(CKEDITOR_ACTION_COMMAND_NAME)
          ?.value as CustomActionCommandValue) ?? null;
      const newValues: FormValues = valueIfActionSelected
        ? {
            ...valueIfActionSelected,
            data: valueIfActionSelected.data
              ? JSON.stringify(valueIfActionSelected.data)
              : '',
          }
        : initialValues;
      return setValues(newValues);
    }
  };

  const actionContainer =
    editor?.ui.view.element?.querySelector(`.${ACTION_TEMPLATE_CLASSNAME}`) ??
    null;

  return (
    <>
      {/* Render button in ckeditor toolbar inside custom plugin template  */}
      {actionContainer &&
        createPortal(
          <button
            className='ck ck-button ck__toolbar_custom_button'
            onClick={onOpenModal}
            type='button'
          >
            <ActionSvg />
            <div>Запрос</div>
          </button>,
          actionContainer
        )}
      <Modal show={isOpen} onHide={onCloseModal}>
        <CloseButton onClose={onCloseModal} />
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Название</Form.Label>
              <Form.Control
                value={values.title}
                name='title'
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.title && !!errors.title}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Адрес отправки запроса</Form.Label>
              <Form.Control
                value={values.url}
                name='url'
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.url && !!errors.url}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Метод запроса</Form.Label>
              <Form.Select
                value={values.method}
                onBlur={handleBlur}
                name='method'
                onChange={manuallyHandleChangeWithTriggerValidation}
              >
                <option value='GET'>GET</option>
                <option value='POST'>POST</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Тело запроса(JSON)</Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                disabled={values.method === 'GET'}
                value={values.data}
                name='data'
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.data}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.data}
              </Form.Control.Feedback>
            </Form.Group>
            <div className='text-end mt-2'>
              <Button
                type='button'
                variant='outline-success'
                className='me-2'
                onClick={onCloseModal}
              >
                Отмена
              </Button>
              <Button type='submit'>Сохранить</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default CustomAction;
