import './style.scss';

import Modal from 'react-bootstrap/Modal';

import CloseButton from '../CloseButton';
import { ModalDialogProps } from './types';

const ModalDialog = (props: ModalDialogProps) => {
  const { isOpen, onClose } = props;

  return (
    <Modal show={isOpen} className='modalDialog' centered onHide={onClose}>
      <CloseButton onClose={onClose} />

      <Modal.Body className='modalDialog__body'>{props.children}</Modal.Body>
    </Modal>
  );
};

export default ModalDialog;
