import './style.scss';

import cn from 'classnames';

import { CloseButtonProps } from './types';

const CloseButton = (props: CloseButtonProps) => {
  const { onClose } = props;
  return (
    <button
      type='button'
      {...props}
      onClick={onClose}
      className={cn('closeButton', props.className)}
    >
      <i className='bx bx-x' />
    </button>
  );
};

export default CloseButton;
