import { TYPE_NODE } from 'constants/graph';
import { useUndoableContext } from 'contexts/undoable';
import { useCallback } from 'react';
import { applyNodeChanges } from 'react-flow-renderer';
import { Edge } from 'reactflow';

export function useUpdateStep() {
  const { getNode, getEdge, setElements } = useUndoableContext();

  const onUpdateStep = useCallback(
    (params: { data: Edge; typeTargetField: 'source' | 'target' }) => {
      const { data, typeTargetField } = params;

      const isNodeExist = getNode(data[typeTargetField]);
      if (!isNodeExist) {
        const initData = getEdge(data.id);
        let position = { x: 0, y: 0 };

        if (initData) {
          position = getNode(initData[typeTargetField])?.position || {
            x: 0,
            y: 0,
          };

          position = { x: position.x + 250, y: position.y }; // 250 - примерно размер блока с отступом
        }

        const childNode = {
          id: data[typeTargetField],
          type: TYPE_NODE,
          data: {
            // @ts-ignore
            label: data[data[typeTargetField]],
            description: '',
            purpose: false,
          },
          position,
        };

        setElements((elements) => ({
          ...elements,
          nodes: applyNodeChanges(
            [{ item: childNode, type: 'add' }],
            elements.nodes ?? []
          ),
          edges: elements.edges
            ? elements.edges.map((edge) => (edge.id === data.id ? data : edge))
            : elements.edges,
        }));
      } else {
        setElements((elements) => ({
          ...elements,
          edges: elements.edges
            ? elements.edges.map((edge) => (edge.id === data.id ? data : edge))
            : elements.edges,
        }));
      }
    },
    [getEdge, getNode, setElements]
  );
  return { onUpdateStep };
}

export default useUpdateStep;
