import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TABLE_PAGINATION_DEF } from 'constants/app_constants';
import { PaginationConfig } from 'models/response';

import {
  createUser,
  deleteUser,
  fetchUsers,
  setModal,
  setTableConfig,
} from './actions';
import { TableConfig, UserConfig, UserState } from './types';

const initialState: UserState = {
  users: [],
  isLoadingUsers: true,
  error: '',
  modal: { type: 'none' },
  tableConfig: {
    ...TABLE_PAGINATION_DEF,
    sortBy: {
      fio: 'asc',
    },
  },
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUsers.pending.type]: () => {
      // state.isLoadingUsers = true;
    },
    [fetchUsers.fulfilled.type]: (
      state,
      action: PayloadAction<PaginationConfig<UserConfig[]>>
    ) => {
      state.users = action.payload.data;
      state.isLoadingUsers = false;
      state.tableConfig.totalSize = action.payload.pagination.total;
      state.error = '';
    },
    [fetchUsers.rejected.type]: (state, action: PayloadAction<string>) => {
      state.users = [];
      state.isLoadingUsers = false;
      state.error = action.payload;
      state.tableConfig = {
        ...TABLE_PAGINATION_DEF,
        sortBy: {
          role: 'asc',
        },
      };
    },

    [createUser.fulfilled.type]: (state) => {
      state.modal = { type: 'none' };
    },

    [deleteUser.fulfilled.type]: (state) => {
      state.modal = { type: 'none' };
    },

    //   ------------------ modal -----------------

    [setModal.type]: (state, action: PayloadAction<ModalConfig>) => {
      state.modal = action.payload;
    },

    //   ------------------ pagination -----------------

    [setTableConfig.type]: (state, action: PayloadAction<TableConfig>) => {
      state.tableConfig = {
        ...state.tableConfig,
        ...action.payload,
      };
    },
  },
});

export default usersSlice.reducer;
