import 'assets/scss/app.scss';

import { RouteElement } from 'components';
import {
  PRIVATE_ROUTES,
  privateRoutes,
  PUBLIC_ROUTES,
  publicRoutes,
} from 'navigation';
import { useEffect, useLayoutEffect } from 'react';
import { shallowEqual } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUser } from 'store/user/actions';

export default function App() {
  const { isLoadingUser, pages } = useAppSelector(
    (state) => ({
      isLoadingUser: state.user.isLoadingUser,
      pages: state.user.user?.pages ?? [],
    }),
    shallowEqual
  );

  const isAuth = useAppSelector((state) => state.auth.isAuth);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (isAuth) dispatch(fetchUser());
  }, [dispatch, isAuth]);

  const privateRoutesByRole = privateRoutes.filter((router) =>
    pages.includes(router.parent)
  );

  useEffect(() => {
    if (isAuth) {
      pathname === PUBLIC_ROUTES.LOGIN.pathTransition &&
        navigate(PRIVATE_ROUTES.SCRIPTS.pathTransition);
    } else {
      navigate(PUBLIC_ROUTES.LOGIN.pathTransition);
    }
  }, [isAuth, navigate, pathname]);

  return (
    <>
      <Routes>
        {publicRoutes.map(({ path, component }, idx) => (
          <Route
            path={path}
            element={<RouteElement component={component} />}
            key={'public_' + idx}
          />
        ))}
        {privateRoutesByRole.map(({ path, component }, idx) => (
          <Route
            path={path}
            element={<RouteElement component={component} layout onlyAuth />}
            key={'private_' + idx}
          />
        ))}
        {!isLoadingUser && (
          <Route
            path='*'
            element={
              <Navigate
                to={
                  isAuth
                    ? PRIVATE_ROUTES.SCRIPTS.pathTransition
                    : PUBLIC_ROUTES.LOGIN.pathTransition
                }
              />
            }
          />
        )}
      </Routes>
    </>
  );
}
