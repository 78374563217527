import './style.scss';

import { ErrorMessageAuth, FormAuth, ModalAuth } from './components';

const Authentication = () => {
  return (
    <ModalAuth>
      <ErrorMessageAuth />
      <FormAuth />
    </ModalAuth>
  );
};

export default Authentication;
