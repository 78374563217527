import './style.scss';

import Dropdown from 'react-bootstrap/Dropdown';

import { ProfileMenuProps } from './types';

const ProfileMenu = (props: ProfileMenuProps) => {
  const { username, position, children } = props;

  return (
    <Dropdown className='profileMenu'>
      <Dropdown.Toggle as='div' className='profileMenu__toggle'>
        <div className='profileMenu__userBox'>
          <span className='text-start title'>{username}</span>

          <span className='text-muted my-0'>{position}</span>
        </div>

        <i className='bx bx-chevron-down' />
      </Dropdown.Toggle>

      <Dropdown.Menu className='profileMenu__menu'>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileMenu;
