import { FormInput } from 'components';
import { useUndoableContext } from 'contexts/undoable';
import { useUpdateNode } from 'hooks';
import { useCallback, useMemo } from 'react';
import { Node } from 'reactflow';

import Editor from '../Editor';
import Сheckbox from '../Сheckbox';
import { PanelNodeProps } from './types';

function PanelNode(props: PanelNodeProps) {
  const { node } = props;
  const { getNode } = useUndoableContext();

  const onChangeState = useUpdateNode(node.id);

  const nodeData = useMemo(
    () => getNode(node.id)?.data ?? {},
    [getNode, node.id]
  );

  const updateValue = useCallback(
    (prop: keyof Node['data'], valueAccessor: 'checked' | 'value') =>
      (input: HTMLInputElement) => {
        if (
          input &&
          input[valueAccessor] !== nodeData[prop] &&
          nodeData[prop] !== undefined
        ) {
          // @ts-ignore
          input[valueAccessor] = nodeData[prop];
        }
      },
    [nodeData]
  );

  return (
    <>
      <div>
        <label htmlFor='node.label'>Название шага:</label>
        <FormInput
          id='label'
          name='label'
          className='form-control'
          key={`node_${node.id}_label`}
          onChangeState={onChangeState}
          defaultValue={node.data.label}
          updateValue={updateValue('label', 'value')}
        />
      </div>
      <div>
        <label htmlFor='node.label'>Текст шага:</label>
        <Editor
          onChangeState={onChangeState}
          name='description'
          defaultValue={node.data.description || ''}
        />
      </div>
      <div className='panelNode__item'>
        <label htmlFor='purpose'>
          <Сheckbox
            id='purpose'
            name='purpose'
            type='checkbox'
            key={`node_${node.id}_is_purpose`}
            onChangeState={onChangeState}
            checked={node.data.purpose}
            updateValue={updateValue('purpose', 'checked')}
          />
          {` Является целью`}
        </label>
      </div>
    </>
  );
}

export default PanelNode;
