import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const usersState = (state: RootState) => state.users;

export const getSelectedUser = createSelector(usersState, (state) => {
  const userData = state.users.find(
    (user) => user.id_user === state.modal.targetId
  );

  if (userData) return userData;

  return {
    id_user: 0,
    fio: '',
    login: '',
    id_role: 1,
    role: '',
    created_at: '',
    password: '',
  };
});

export const getQueryTable = (state: any) => {
  const tableConfig = state.users.tableConfig;
  const [keyFieldSort, typeFieldSort] = Object.entries(tableConfig.sortBy)[0];

  const queryObj = {
    count: tableConfig.size,
    page: tableConfig.page,
    sort_by: keyFieldSort,
    sort_type: typeFieldSort,
  };

  return queryObj;
};
