import { useUndoableContext } from 'contexts/undoable';
import { NodeProps } from 'react-flow-renderer';

type Keys = keyof NodeData;
type Values = NodeData[Keys];

export function useUpdateNode(id: NodeProps['id']) {
  const { setElements } = useUndoableContext();

  const updateData = (data: { [K in Keys]?: Values }) => {
    setElements((elems) => ({
      ...elems,
      nodes: elems.nodes?.map((node) =>
        node.id === id
          ? {
              ...node,
              data: { ...node.data, ...data },
            }
          : node
      ),
    }));
  };
  return updateData;
}

export default useUpdateNode;
