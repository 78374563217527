import './style.scss';

import { Col, Container, Row } from 'react-bootstrap';

import { FooterProps } from './types';

const Footer = ({ author, tagline }: FooterProps): JSX.Element => {
  return (
    <footer className='footer'>
      <Container fluid>
        <Row>
          <Col>
            <span className='footer__text'>{author}</span>
          </Col>
          <Col className='footer__text'>
            <span>{tagline}</span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
