import { useField } from 'formik';
import { usePasswordGenerator } from 'hooks';
import Button from 'react-bootstrap/Button';

import { ButtonGenerationPasswordProps } from './types';

function ButtonGenerationPassword({
  fieldName,
  className,
}: ButtonGenerationPasswordProps) {
  const [, , helpers] = useField(fieldName);
  const passwordGenerator = usePasswordGenerator();

  const onClick = () => {
    helpers.setValue(passwordGenerator());
  };

  return (
    <Button variant='outline-primary' className={className} onClick={onClick}>
      Сгенерировать пароль
    </Button>
  );
}

export default ButtonGenerationPassword;
