import './style.scss';

import logoLightSvg from 'assets/images/logo.svg';
import { Link } from 'react-router-dom';

const BrandBox = () => {
  return (
    <div className='brandBox'>
      <Link to='' className='brandBox__link'>
        <img src={logoLightSvg} alt='' className='brandBox__img' />
      </Link>
    </div>
  );
};

export default BrandBox;
