import { usePrompt } from 'hooks';

import { PromptProps } from './types';

const Prompt = ({ message, when }: PromptProps) => {
  usePrompt(message, when);

  return null;
};

export default Prompt;
