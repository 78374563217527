import './style.scss';

import { CloseButton } from 'components';
import { VariableSvg } from 'components/Icons';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import ModalContent from './components/ModalContent/ModalContent';
import { CustomVariableProps } from './types';

const VARIABLE_TEMPLATE_CLASSNAME =
  'ckeditor5__plugin_toolbar_template_variable';

function CustomVariable({ editor }: CustomVariableProps) {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const variableContainer =
    editor?.ui.view.element?.querySelector(`.${VARIABLE_TEMPLATE_CLASSNAME}`) ??
    null;

  return (
    <div>
      {variableContainer &&
        createPortal(
          <button
            className='ck ck-button ck__toolbar_custom_button'
            onClick={onOpenModal}
            type='button'
          >
            <VariableSvg />
            <div>Поле ввода</div>
          </button>,
          variableContainer
        )}
      <Modal
        show={isOpen}
        onHide={onCloseModal}
        className='customvariable__modal'
      >
        <CloseButton onClose={onCloseModal} />
        <Modal.Body className='customvariable__modal_body'>
          <ModalContent closeModal={onCloseModal} editor={editor} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CustomVariable;
