import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchUser } from './actions';
import { UserConfig, UserState } from './types';

const initialState: UserState = {
  isLoadingUser: true,
};

export const scriptsState = createSlice({
  name: 'scripts',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.pending.type]: (state) => {
      state.isLoadingUser = true;
    },
    [fetchUser.fulfilled.type]: (state, action: PayloadAction<UserConfig>) => {
      state.isLoadingUser = false;
      state.user = action.payload;
    },
  },
});

export default scriptsState.reducer;
