import { ControlsInTable } from 'components';
import { PRIVATE_ROUTES } from 'navigation';
import { useCallback } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import { fetchFormByScript, setEditorScriptId } from 'store/editor/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { copyScript, setModal, updateScript } from 'store/scripts/actions';
import { getEditedScript, getIsScriptEdited } from 'store/scripts/selectors';
import { setRowEdit } from 'store/scripts/slice';

import { ControlsProps } from './types';

const Controls = ({ script, formId }: ControlsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id_script: scriptId } = script;

  const isEdit = useAppSelector(getIsScriptEdited(scriptId));
  const editedScript = useAppSelector(getEditedScript(scriptId));

  const onEdit = () => {
    dispatch(setEditorScriptId({ scriptId }));
    navigate(PRIVATE_ROUTES.EDITOR.id);
  };

  const onDelete = () => {
    dispatch(setModal({ type: 'delete', targetId: scriptId }));
  };

  const onCopy = () => {
    dispatch(copyScript({ scriptId }));
  };

  const onUploadForm = () => {
    dispatch(fetchFormByScript({ formId }));
  };

  const onRowEditToggle = useCallback(async () => {
    try {
      if (isEdit && typeof editedScript === 'object') {
        const { goal, name } = editedScript;
        await dispatch(updateScript({ id: scriptId, name, goal })).unwrap();
      }
      dispatch(setRowEdit(script));
    } catch {}
  }, [isEdit, editedScript, dispatch, script, scriptId]);

  return (
    <ControlsInTable editable onRowEdit={onRowEditToggle} isEdit={isEdit}>
      <Dropdown.Item onClick={onUploadForm}>Выгрузить форму</Dropdown.Item>
      <Dropdown.Item onClick={onEdit}>Редактировать</Dropdown.Item>
      <Dropdown.Item className='dropdown-item' onClick={onCopy}>
        Копировать для шаблона
      </Dropdown.Item>
      <Dropdown.Item className='dropdown-item primary-color' onClick={onDelete}>
        Удалить
      </Dropdown.Item>
    </ControlsInTable>
  );
};

export default Controls;
