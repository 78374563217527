import './style.scss';

import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import FormCreateEdge from '../FormCreateEdge';
import Separator from '../Separator';
import { PanelAddEdgeProps } from './types';

function PanelAddEdge({
  typeTargetField,
  nodeParent,
  title,
}: PanelAddEdgeProps) {
  const [isShowPanel, setIsShowPanel] = useState<boolean>();

  const onClick = () => {
    setIsShowPanel((isShow) => !isShow);
  };

  return (
    <>
      <Separator title={title}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <i
          onClick={onClick}
          onKeyDown={onClick}
          className={`toggle bx ${
            isShowPanel ? 'bx-minus-circle' : 'bx-plus-circle'
          }`}
          title='Добавить ответ'
        ></i>
      </Separator>
      {isShowPanel && (
        <Accordion defaultActiveKey='true'>
          <Accordion.Item eventKey={isShowPanel + ''}>
            <Accordion.Body>
              <FormCreateEdge
                key={`form_create_edge_${nodeParent.id}`}
                typeTargetField={typeTargetField}
                nodeParent={nodeParent}
                callBack={onClick}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
}

export default PanelAddEdge;
