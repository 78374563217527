import './style.scss';

import { ErrorMessage } from 'formik';

import { ErrorMessageFormProps } from './types';

const ErrorMessageForm = ({ name }: ErrorMessageFormProps) => {
  return <ErrorMessage name={name} className='errorMessage' component='div' />;
};

export default ErrorMessageForm;
