import { AxiosResponse } from 'axios';
import { ENDPOINTS } from 'constants/api_endpoints';
import { IResponse, PaginationConfig } from 'models/response';
import axiosApi from 'utils/api_helper';

import {
  ScriptConfig,
  ScriptUpdateConfig,
  ScriptСreationConfig,
} from './types';

export default class ScriptsService {
  static async fetchScripts(params: { query: Record<string, unknown> }) {
    const response: AxiosResponse<IResponse<PaginationConfig<ScriptConfig[]>>> =
      await axiosApi.get(ENDPOINTS.SCRIPT, { params: params.query });

    return response;
  }

  static async createScript(scriptData: ScriptСreationConfig) {
    const response: AxiosResponse<IResponse<[{ id: number }]>> =
      await axiosApi.post(ENDPOINTS.SCRIPT, scriptData);

    return response;
  }

  static async updateScript(scriptData: ScriptUpdateConfig) {
    const response = await axiosApi.put(
      `${ENDPOINTS.SCRIPT_UPDATE}/${scriptData.id}`,
      scriptData
    );

    return response;
  }

  static async deleteScript(scriptId: number) {
    const response: AxiosResponse<IResponse<any>> = await axiosApi.delete(
      `${ENDPOINTS.SCRIPT}/${scriptId}`
    );

    return response;
  }

  static async copyScript(scriptId: number) {
    const response: AxiosResponse<IResponse<any>> = await axiosApi.get(
      `${ENDPOINTS.EDITOR_COPY}/${scriptId}`
    );

    return response;
  }

  static async getScriptsGoals() {
    const response: AxiosResponse<IResponse<any>> = await axiosApi.get(
      `${ENDPOINTS.EDITOR_COPY}`
    );

    return response;
  }
}
