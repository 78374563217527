import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TABLE_PAGINATION_DEF } from 'constants/app_constants';
import { PaginationConfig } from 'models/response';

import { fetchRoles, setTableConfig } from './actions';
import { RoleConfig, RolesState } from './types';

const initialState: RolesState = {
  roles: [],
  isLoadingRoles: true,
  tableConfig: {
    ...TABLE_PAGINATION_DEF,
    sortBy: {
      role: 'asc',
    },
  },
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRoles.pending.type]: () => {
      // state.isLoadingRoles = true;
    },
    [fetchRoles.fulfilled.type]: (
      state,
      action: PayloadAction<PaginationConfig<RoleConfig[]>>
    ) => {
      state.roles = action.payload.data;
      state.isLoadingRoles = false;
      state.tableConfig.totalSize = action.payload.pagination.total;
    },
    [fetchRoles.rejected.type]: (state) => {
      state.roles = [];
      state.isLoadingRoles = false;
      state.tableConfig = {
        ...TABLE_PAGINATION_DEF,
        sortBy: {
          role: 'asc',
        },
      };
    },

    //   ------------------ pagination -----------------
    [setTableConfig.type]: (state, action: PayloadAction<TableConfig<any>>) => {
      state.tableConfig = {
        ...state.tableConfig,
        ...action.payload,
      };
    },
  },
});

export default rolesSlice.reducer;
