import classNames from 'classnames';
import { RouteType } from 'navigation/types';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  item: RouteType;
  activeMenu: string | null;
}

const NavItem: FC<Props> = ({ item, activeMenu }) => {
  return (
    <div
      key={item.id}
      className={classNames('toolbar__item', {
        active: item.id === activeMenu,
      })}
    >
      <Link to={item.path}>
        <i className={`bx ${item.icon} toolbar__item_icon`}></i>
        <span className='toolbar__item_text'>{item.title}</span>
      </Link>
    </div>
  );
};

export default NavItem;
