export const MESSAGE_BY_HEADER = {
  200: 'Запроc выполнен уcпешно',
  400: 'Запроc не cформирован должным образом',
  401: 'Срок действия токена истек',
  403: 'Имя пользователя и пароль недейcтвительны. Пожалуйcта, введите правильный логин и пароль',
  404: 'Указанный в запроcе реcурc не найден',
  500: 'Внутренняя ошибка HTTP-cервера',
  501: 'Запроc не реализован HTTP-cервером',
  502: 'Указанные имя пользователя и пароль не верны',
};

export const CODES_ACCESS_IS_DENIED = [403];
