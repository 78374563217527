import { Authentication, Editor, Logout, Scripts, Users } from 'pages';
import ScriptSettings from 'pages/ScriptSettings/ScriptSettings';
import { Navigate } from 'react-router-dom';

import { RouterTypePrivate, RouterTypePublic } from './types';

export const PRIVATE_ROUTES = {
  USERS: {
    id: 'users',
    parent: 'users',
    title: 'Пользователи',
    path: '/users',
    pathTransition: '/users',
    component: Users,
    userType: 'user',
    icon: 'bx-user-circle',
    children: {},
  },
  SCRIPTS: {
    id: 'scripts',
    parent: 'scripts',
    title: 'Скрипты',
    path: '/scripts',
    pathTransition: '/scripts',
    component: Scripts,
    userType: 'user',
    icon: 'bx bx-task',
    children: {},
  },
  SCRIPT_SETTINGS: {
    id: 'script-settings',
    parent: 'scripts',
    title: 'Настройки скрипта',
    path: '/scripts/settings',
    pathTransition: '/scripts/settings',
    component: ScriptSettings,
    userType: 'user',
    icon: 'bx bx-cog',
    children: {},
  },
  EDITOR: {
    id: 'editor',
    parent: 'scripts',
    title: 'Редактор',
    path: '/scripts/editor',
    pathTransition: '/scripts/editor',
    component: Editor,
    userType: 'user',
    icon: 'bx bx-plug',
    children: {},
  },
} satisfies RouterTypePrivate;

export const PUBLIC_ROUTES = {
  LOGIN: {
    id: 'login',
    parent: 'login',
    title: 'Авторизация',
    path: '/login',
    pathTransition: '/login',
    component: Authentication,
    children: {},
  },
  LOGOUT: {
    id: 'logout',
    parent: 'logout',
    title: 'Выход',
    path: '/logout',
    pathTransition: '/logout',
    component: Logout,
    children: {},
  },

  DEFAULT: {
    id: 'defaultPage',
    parent: 'defaultPage',
    title: '',
    path: '/',
    pathTransition: '/',
    component: () => <Navigate to={PRIVATE_ROUTES.SCRIPTS.pathTransition} />,
    children: {},
  },
  // NOTFOUND: {
  //   id: 'notFoundPath',
  //   parent: 'roles',
  //   title: '404',
  //   path: '*',
  //   pathTransition: '*',
  //   component: () => <NotFound />,
  //   children: {},
  // },
} satisfies RouterTypePublic;

// const NotFound = () => {
//   return (
//     <div className='row justify-content-md-center'>
//       <h1>Веб-страница недоступна!</h1>
//     </div>
//   );
// };

export const publicRoutes = Object.values(PUBLIC_ROUTES);
export const privateRoutes = Object.values(PRIVATE_ROUTES);
