import { UndoableContextProvider } from 'contexts/undoable';
import { useEffect } from 'react';
import { fetchEditorScript } from 'store/editor/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Graph } from './Graph';

const Editor = () => {
  const { initialScript, isLoadingScript } = useAppSelector(
    (state) => state.editor
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchEditorScript());
  }, [dispatch]);

  if (!initialScript || isLoadingScript) return null;

  return (
    <UndoableContextProvider initialScript={initialScript}>
      <Graph />
    </UndoableContextProvider>
  );
};

export default Editor;
