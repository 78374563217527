import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { setStatus } from 'store/statusApp/actions';

import { initialScript } from './data';
import EditorService from './service';
import { setCrmFields, setInitialScript } from './slice';
import { EditorState, InitialScript } from './types';

export const setEditorScriptId = createAction<{ scriptId?: number }>(
  'editor/setEditorScriptId'
);

export const fetchEditorScript = createAsyncThunk<
  InitialScript | undefined,
  undefined,
  { rejectValue: string; state: { editor: EditorState } }
>(
  'editor/fetchEditorScript',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const scriptId = getState().editor.selectedEditorScriptId;

      if (!scriptId) {
        dispatch(setStatus({ status: 'warning', message: 'Скрипт не выбран' }));
        return undefined;
      }

      const response = await EditorService.fetchEditorScript({ scriptId });
      const crmFieldsData = await EditorService.fetchCrmFields();
      dispatch(setCrmFields(crmFieldsData));
      return response.data.data;
    } catch (error) {
      let errorMessage = 'Неизвестная проблема';

      if (error instanceof AxiosError) {
        errorMessage = error.message;

        if (error.response?.data) {
          errorMessage = '';
          return initialScript;
        }
      }
      if (errorMessage !== '') {
        dispatch(setStatus({ status: 'warning', message: errorMessage }));
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateEditorScript = createAsyncThunk<
  boolean,
  InitialScript,
  { state: { editor: EditorState } }
>(
  'editor/updateEditorScript',
  async (scriptData: InitialScript, { dispatch, getState }) => {
    try {
      const scriptId = getState().editor.selectedEditorScriptId;

      if (scriptId) {
        const response = await EditorService.updateEditorScript({
          scriptId,
          data: scriptData,
        });
        dispatch(setInitialScript(scriptData));
        await EditorService.createFormByScript({ scriptId });

        dispatch(
          setStatus({ status: 'success', message: response.data.message })
        );
      }

      return true;
    } catch (error) {
      let errorMessage = 'Неизвестная проблема';

      if (error instanceof AxiosError) {
        errorMessage = error.message;

        if (error.response?.data) {
          errorMessage = error.response.data.message;
        }
      }

      dispatch(setStatus({ status: 'warning', message: errorMessage }));
      return false;
    }
  }
);

export const fetchFormByScript = createAsyncThunk<
  boolean,
  { formId: string | null }
>('editor/fetchFormByScript', async ({ formId }, { dispatch }) => {
  try {
    if (!formId) {
      dispatch(setStatus({ status: 'warning', message: 'Форма отсутствует' }));
      return false;
    }

    const response = await EditorService.fetchFormByScript({ formId });

    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `форма_${formId}.json`); // or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    return true;
  } catch (error) {
    let errorMessage = 'Неизвестная проблема';

    if (error instanceof AxiosError) {
      errorMessage = error.message;

      if (error.response?.data) {
        errorMessage = error.response.data.message;
      }
    }

    dispatch(setStatus({ status: 'warning', message: errorMessage }));
    return false;
  }
});
