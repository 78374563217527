import { AxiosResponse } from 'axios';
import { ENDPOINTS } from 'constants/api_endpoints';
import { IResponse } from 'models/response';
import axiosApi from 'utils/api_helper';

import { UserConfig } from './types';

export default class UserService {
  static async fetchUser() {
    const response: AxiosResponse<IResponse<UserConfig[]>> = await axiosApi.get(
      ENDPOINTS.USERS_FETCH_CURRENT
    );

    return response;
  }
}
