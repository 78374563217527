import './style.scss';

import { useUndoableContext } from 'contexts/undoable';
import { Button } from 'react-bootstrap';
import { updateEditorScript } from 'store/editor/actions';
import { useAppDispatch } from 'store/hooks';

function ButtonSave({ callbackAfterSave }: { callbackAfterSave?: () => void }) {
  const { elements } = useUndoableContext();
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(updateEditorScript(elements));
    callbackAfterSave?.();
  };

  return (
    <Button className='buttonSave' onClick={onClick}>
      Сохранить
    </Button>
  );
}
export default ButtonSave;
