import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

interface Props {
  initCss?: string;
  onChangeCssInjection: (newCss: string) => void;
}

const CssInjection: FC<Props> = ({ initCss = '', onChangeCssInjection }) => {
  const [value, setValue] = useState(initCss);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onChangeCssInjection(value);
  };

  useEffect(() => {
    if (value !== initCss) setValue(initCss);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initCss]);

  return (
    <>
      <div>CSS</div>
      <Form onSubmit={onSubmit} className='flex_max_height'>
        <Form.Control
          as='textarea'
          className='script_settings_css_textarea'
          value={value}
          onChange={onChange}
        />
        <div className='mt-2 text-end'>
          <Button type='submit'>Сохранить</Button>
        </div>
      </Form>
    </>
  );
};
export default CssInjection;
