import { useFormik } from 'formik';
import { useUpdateEdge, useUpdateStep } from 'hooks';
import { Edge } from 'reactflow';

import EdgeFields from '../EdgeFields';
import { WebhookItemType } from '../EdgeFields/components/WebhooksList/types';
import ItemEdge from '../ItemEdge';
import { FormEditEdgeProps } from './types';

function FormEditEdge(props: FormEditEdgeProps) {
  const { edge, typeTargetField, callBack } = props;
  const { onUpdateEdgeData } = useUpdateEdge(edge.id);
  const { onUpdateStep } = useUpdateStep();
  const typeSourceField = typeTargetField === 'source' ? 'target' : 'source';

  const formik = useFormik<Edge>({
    initialValues: {
      ...edge,
    },
    onSubmit: (data) => {
      onUpdateStep({ data, typeTargetField });
      callBack();
    },
  });

  const onChangeState = (field: {
    [key: string]: string | boolean | WebhookItemType[];
  }) => {
    const [key, value] = Object.entries(field)[0];
    if (key.indexOf('data') > -1) {
      onUpdateEdgeData({ [key.split('.')[1]]: value });
    }
    formik.handleChange({
      target: {
        name: key,
        value,
      },
    });
  };

  return (
    <ItemEdge
      edge={formik.values}
      key={edge.id}
      typeTargetField={typeTargetField}
    >
      <form onSubmit={formik.handleSubmit}>
        <EdgeFields
          edge={{ ...edge, ...formik.values }}
          typeTargetField={typeTargetField}
          typeSourceField={typeSourceField}
          onChangeState={onChangeState}
        />
      </form>
    </ItemEdge>
  );
}

export default FormEditEdge;
