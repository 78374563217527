import { useUndoableContext } from 'contexts/undoable';
import { useCallback } from 'react';
import { Edge, Node } from 'react-flow-renderer';

export function useSelectingStep() {
  const { setElements } = useUndoableContext();

  const onSelectingNode = useCallback(
    (nodeId: Node['id']) => {
      setElements((elements) => ({
        ...elements,
        nodes: elements.nodes?.map((node) => ({
          ...node,
          selected: node.id === nodeId,
        })),
        edges: elements.edges?.map((edge) => ({
          ...edge,
          selected: false,
        })),
      }));
    },
    [setElements]
  );

  const onSelectingEdge = useCallback(
    (edgeId: Edge['id']) => {
      setElements((elements) => ({
        ...elements,
        nodes: elements.nodes?.map((node) => ({
          ...node,
          selected: false,
        })),
        edges: elements.edges
          ? [
              ...elements.edges.map((edge) => ({
                ...edge,
                selected: edge.id === edgeId,
              })),
            ]
          : elements.edges,
      }));
    },
    [setElements]
  );

  const onSelectingStep = useCallback(
    (params: { nodeId?: Node['id']; edgeId?: Edge['id'] }) => {
      params.nodeId && onSelectingNode(params.nodeId);
      params.edgeId && onSelectingEdge(params.edgeId);
    },
    [onSelectingEdge, onSelectingNode]
  );

  const onUnselectAll = useCallback(() => {
    setElements((elements) => ({
      ...elements,
      nodes: elements.nodes?.map((node) => ({
        ...node,
        selected: false,
      })),
      edges: elements.edges?.map((edge) => ({
        ...edge,
        selected: false,
      })),
    }));
  }, [setElements]);

  return { onSelectingNode, onSelectingEdge, onSelectingStep, onUnselectAll };
}

export default useSelectingStep;
