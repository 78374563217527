import './style.scss';

import { ModalDialog } from 'components';
import { Field, Form, Formik } from 'formik';
import { PRIVATE_ROUTES } from 'navigation';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createScript, setModal } from 'store/scripts/actions';
import { getSelectedScript } from 'store/scripts/selectors';
import { ScriptСreationConfig } from 'store/scripts/types';

const ModalCreateScript = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { modal, scriptData } = useAppSelector((state) => ({
    modal: state.scripts.modal,
    scriptData: getSelectedScript(state),
  }));

  const initialValues = scriptData;

  const onCloseModal = () => {
    dispatch(setModal({ type: 'none' }));
  };

  const onSubmit = (values: ScriptСreationConfig) => {
    dispatch(createScript(values))
      .unwrap()
      .then(() => navigate(PRIVATE_ROUTES.EDITOR.id));
  };

  return (
    <ModalDialog
      isOpen={modal.type === 'create' || modal.type === 'edit'}
      onClose={onCloseModal}
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <div className='modalCreateScript'>
            <h6 className='modalCreateScript__title'>
              Создание нового скрипта
            </h6>

            <div className='modalCreateScript__content'>
              <div className='modalCreateScript__item'>
                <label htmlFor='name'>Название:</label>
                <Field
                  id='name'
                  name='name'
                  as='textarea'
                  className='form-control modalCreateScript__textArea_noResize'
                  placeholder='Скрипт название'
                />
              </div>
              <div className='modalCreateScript__item'>
                <label htmlFor='goal'>Цель скрипта:</label>
                <Field
                  id='goal'
                  name='goal'
                  as='textarea'
                  className='form-control modalCreateScript__textArea_noResize'
                  placeholder='Цель скрипта'
                />
              </div>
            </div>

            <Button type='submit' className='modalCreateScript__button_submit'>
              Сохранить
            </Button>
          </div>
        </Form>
      </Formik>
    </ModalDialog>
  );
};

export default ModalCreateScript;
