import { PUBLIC_ROUTES } from 'navigation';
import { Navigate } from 'react-router-dom';
import { logout } from 'store/auth/actions';
import { useAppDispatch } from 'store/hooks';

export const Logout = () => {
  const dispatch = useAppDispatch();
  dispatch(logout());

  return <Navigate to={{ pathname: PUBLIC_ROUTES.LOGIN.pathTransition }} />;
};
