import { useUndoableContext } from 'contexts/undoable';
import { FC, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { crmFieldsSelector } from 'store/editor/selectors';
import { useAppSelector } from 'store/hooks';

import {
  changeVariableFields,
  getCustomVariableFields,
} from '../../../../helpers/editor-helpers';
import SideNavigation from '../SideNavigation/SideNavigation';
import CrmVariableTab from '../SuiteCrmVariable/CrmVariableTab';
import { getAllKeysFromEntities, getDifferenceInKeys } from './helpers';
import { Field, ModalContentProps, SelectedValues } from './types';

const ModalContent: FC<ModalContentProps> = ({ closeModal, editor }) => {
  const [selectedCrm, setCelectedCrm] = useState<string>('suite');
  const [values, setValues] = useState<SelectedValues>([]);
  const { elements, setElements } = useUndoableContext();

  const crmFields = useAppSelector(crmFieldsSelector);

  const crmFieldsArray = Object.entries(crmFields);

  const selectValue = (field: Field) => {
    setValues((state) => [...state, field]);
  };

  const removeValue = (field: Field) => {
    setValues((state) => state.filter((item) => item.value !== field.value));
  };

  const onSave = () => {
    if (editor) {
      changeVariableFields(editor, values);
      const crmEntityKeys = getAllKeysFromEntities(crmFieldsArray);
      const difference = getDifferenceInKeys(crmEntityKeys, elements.crmKeys);
      if (difference.length !== 0) {
        setElements((state) => ({
          ...state,
          crmKeys: Object.fromEntries(crmEntityKeys),
        }));
      }
      closeModal();
    }
  };

  useEffect(() => {
    if (editor) {
      const fieldsInEditor = getCustomVariableFields(editor.model);
      const fields: SelectedValues = [];
      for (const field of fieldsInEditor) {
        const fieldName = field.getAttribute('name') as string | undefined;
        const fieldLabel = field.getAttribute('label') as string | undefined;
        if (fieldName) {
          fields.push({ value: fieldName, label: fieldLabel });
        }
      }
      setValues(fields);
    }
  }, [editor]);

  return (
    <>
      <SideNavigation
        selectedTab={selectedCrm}
        changeSelectedTab={setCelectedCrm}
      />
      <div className='customvariable__modal_tab_wrapper'>
        {crmFieldsArray.map(([crmKey, crm]) => {
          if (crmKey === selectedCrm)
            return (
              <CrmVariableTab
                key={crmKey}
                crm={crm}
                crmKey={crmKey}
                removeField={removeValue}
                selectField={selectValue}
                values={values}
              />
            );
          return null;
        })}
        <div className='text-end mt-4'>
          <Button
            type='button'
            variant='outline-success'
            className='me-2'
            onClick={closeModal}
          >
            Отмена
          </Button>
          <Button onClick={onSave}>Сохранить</Button>
        </div>
      </div>
    </>
  );
};
export default ModalContent;

// const testCrmFields = {
//   suite: {
//     crm_name: 'SuiteCRM',
//     Contacts: {
//       module_name: 'Контакты',
//       fields: {
//         first_name: 'Имя',
//         last_name: 'Фамилия',
//         phone_mobile: 'Тел.(моб.)',
//         primary_address_postalcode: 'Почтовый код',
//         primary_address_state: 'Область',
//         primary_address_city: 'Город',
//         primary_address_street: 'Адрес',
//         description: 'Описание',
//       },
//       key: 'id',
//     },
//     Leads: {
//       module_name: 'Leads',
//       fields: {
//         first_name: 'Имя',
//         last_name: 'Фамилия',
//         phone_mobile: 'Тел.(моб.)',
//         primary_address_postalcode: 'Почтовый код',
//         primary_address_state: 'Область',
//         primary_address_city: 'Город',
//         primary_address_street: 'Адрес',
//         description: 'Описание',
//       },
//       key: 'description',
//     },
//   },
//   bitrix: {
//     crm_name: '123',
//     Leads: {
//       module_name: 'Leads',
//       fields: {
//         first_name: 'Имя',
//         last_name: 'Фамилия',
//         phone_mobile: 'Тел.(моб.)',
//         primary_address_postalcode: 'Почтовый код',
//         primary_address_state: 'Область',
//         primary_address_city: 'Город',
//         primary_address_street: 'Адрес',
//         description: 'Описание',
//       },
//       key: 'description',
//     },
//   },
// }; //satisfies AllCrmFields;

// const crmEntityKeys = getAllKeysFromEntities(Object.entries(testCrmFields));
// console.log('crmEntityKeys :>> ', crmEntityKeys);
// const difference = getDifferenceInKeys(crmEntityKeys, {
//   // 'suite:Contacts': 'id',
//   'bitrix:Leads': 'description',
//   'suite:Leads': 'descriptio',
// });
// console.log('difference :>> ', difference);
