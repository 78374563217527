import './style.scss';

import { TYPES_ARROW } from 'constants/graph';
import { useRemoveEdge } from 'hooks';
import { EdgeProps, getEdgeCenter } from 'react-flow-renderer';

const foreignObjectSize = 180;

export default function Edge(props: EdgeProps<EdgeData>) {
  const { id, sourceX, sourceY, targetX, targetY, data } = props;

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const onClickDelete = useRemoveEdge(id);

  const style = TYPES_ARROW[data?.type || 'neutral'];

  const markerId = `marker-${id}`.replace('=>', '');

  return (
    <>
      <defs>
        <marker
          id={`triangle-${markerId}`}
          viewBox='0 0 10 10'
          refX='12'
          refY='5'
          markerWidth={style.markerEnd.width}
          markerHeight={style.markerEnd.height}
          orient='auto'
        >
          <path
            id={markerId}
            d='M 0 0 L 10 5 L 0 10'
            fill='none'
            stroke={style.markerEnd.color}
            strokeWidth={2}
          />
        </marker>
      </defs>
      <path
        id={id}
        strokeWidth={3}
        className='react-flow__edge-path'
        d={`M ${sourceX},${sourceY}L ${targetX},${targetY}`}
        style={style.style}
        markerEnd={`url(#triangle-${markerId})`}
      />
      <foreignObject
        width={foreignObjectSize}
        height={60}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 8}
        className='edge edgebutton-foreignobject p-2'
      >
        <div>
          <p style={{ fontSize: '13px' }}>{data?.label}</p>
          <button
            className='edge__button  edge__button_remove'
            onClick={(e) => {
              e.stopPropagation();
              onClickDelete();
            }}
          >
            ×
          </button>
        </div>
      </foreignObject>
    </>
  );
}
