import { MarkerType } from 'reactflow';
export const TYPE_NODE = 'node';
export const TYPE_EDGE = 'edge';

const negativeArrow = {
  style: {
    stroke: '#cf6464',
    strokeWidth: '2px',
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 4,
    height: 4,
    color: '#cf6464',
  },
};

const positiveArrow = {
  style: {
    stroke: '#34C38F',
    strokeWidth: '2px',
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 4,
    height: 4,
    color: '#34C38F',
  },
};

const neutralArrow = {
  style: {
    stroke: '#F1B44C',
    strokeWidth: '2px',
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 4,
    height: 4,
    color: '#F1B44C',
  },
};

export const TYPES_ARROW = {
  negative: negativeArrow,
  positive: positiveArrow,
  neutral: neutralArrow,
};

export const DEF_NODE_DATA: NodeData = {
  label: `Новый шаг`,
  description: '',
  purpose: false,
};

export const DEF_EDGE_DATA: EdgeData = {
  description: '',
  type: 'neutral',
  label: 'Новый ответ',
};
