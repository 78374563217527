import { Field, useField } from 'formik';
import { useLoginGenerator } from 'hooks';

import { FieldFIOProps } from './types';

// eslint-disable-next-line react-hooks/rules-of-hooks
const loginGenerator = useLoginGenerator();

function FieldFIO({ fieldName, fieldNameWithLogin }: FieldFIOProps) {
  const [, , helpersLogin] = useField(fieldNameWithLogin);
  const [, , helpersFio] = useField(fieldName);

  const onChange = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const login = loginGenerator(evnt.target.value);
    helpersLogin.setValue(login);
    helpersFio.setValue(evnt.target.value);
  };

  return (
    <Field
      name={fieldName}
      id={fieldName}
      className='form-control'
      onChange={onChange}
    />
  );
}

export default FieldFIO;
