import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCookie } from 'utils/app_helper';

import { login, logout } from './actions';
import { AuthState } from './types';

const initialState: AuthState = {
  isAuth: !!getCookie('Authorization'),
  isLoading: false,
  error: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuth = true;
        state.error = '';
      })
      // PayloadAction<string>
      .addCase(login.rejected, (state, action: unknown) => {
        state.isLoading = false;
        state.isAuth = false;
        state.error = (action as PayloadAction<string>).payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuth = false;
      });
  },
});

export default authSlice.reducer;
