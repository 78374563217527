import { colors } from 'components/Select/constants';
import { useUndoableContext } from 'contexts/undoable';
import { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { uuid } from 'utils';

import useOptions from './hook';
import { InputSearchNodeProps } from './types';

function CreatableSearchNode({
  nodeId,
  currentNodeId,
  name,
  onChangeState,
}: InputSearchNodeProps) {
  const { getNode } = useUndoableContext();
  const selectedNode = getNode(nodeId + '');
  const { options, setOptions } = useOptions(currentNodeId);
  //  const [options, setOptions] = useState(getOptions());
  const [newId] = useState(uuid());
  const [value, setValue] = useState<Option | null>(
    selectedNode
      ? {
          value: selectedNode.id || '',
          label: selectedNode.data.label || '',
        }
      : null
  );

  useEffect(() => {
    const node = getNode(value?.value as string);
    const currentlabel = node?.data.label;
    if (
      currentlabel &&
      node.id === value?.value &&
      currentlabel !== value?.label
    ) {
      setValue((st) => (st ? { ...st, label: currentlabel } : st));
    }
  }, [getNode, nodeId, value?.label, value?.value]);

  const createOption = (label: string) => ({
    label,
    value: newId,
    isDisabled: false,
  });

  const onCreateOption = (inputValue: string) => {
    const newOption = createOption(inputValue);
    setOptions((prev) => [
      ...prev.filter((op) => op.value !== newId),
      newOption,
    ]);
    setValue(newOption);

    onChangeState({ [newId]: inputValue });
    onChangeState({ [name]: newId });
  };

  const onChange = (newValue: SingleValue<Option> & { __isNew__: boolean }) => {
    if (!newValue) {
      setValue(null);
      onChangeState({ [name]: '-1' });
      return;
    }

    if (newValue.__isNew__) {
      return true;
    }

    setValue(newValue);
    onChangeState({ [name]: newValue.value + '' });
  };

  return (
    <CreatableSelect
      isClearable
      onCreateOption={onCreateOption}
      options={options}
      placeholder='Выбрать шаг...'
      value={value}
      // @ts-ignore
      onChange={onChange}
      theme={(theme) => ({
        ...theme,
        spacing: {
          baseUnit: 2,
          controlHeight: 34,
          menuGutter: 1,
        },
        colors: {
          ...theme.colors,
          ...colors,
        },
      })}
      formatCreateLabel={(label) => (
        <div>Создать новый шаг: {`"${label}"`} ?</div>
      )}
    />
  );
}

export default CreatableSearchNode;
