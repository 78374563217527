import './style.scss';

import { SeparatorProps } from './types';

function Separator({ title, children }: SeparatorProps) {
  return (
    <div className='separator'>
      <span>{title} </span>
      {children}
    </div>
  );
}

export default Separator;
