import { ModalDeleteContent, ModalDialog } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteScript, setModal } from 'store/scripts/actions';
import { getSelectedScript } from 'store/scripts/selectors';

const ModalDeleteUser = () => {
  const { modal, scriptData } = useAppSelector((state) => ({
    modal: state.scripts.modal,
    scriptData: getSelectedScript(state),
  }));

  const dispatch = useAppDispatch();
  if (!scriptData) return null;

  const onClose = () => {
    dispatch(setModal({ type: 'none' }));
  };

  const onApply = () => {
    dispatch(deleteScript({ scriptId: scriptData.id_script }));
    // dispatch(setModal({ type: 'none' }));
  };

  return (
    <ModalDialog isOpen={modal.type === 'delete'} onClose={onClose}>
      <ModalDeleteContent
        actionName='Удалить скрипт ?'
        targetName={scriptData.name}
        data={'Цель: ' + scriptData.goal}
        onCancel={onClose}
        onApply={onApply}
      />
    </ModalDialog>
  );
};

export default ModalDeleteUser;
