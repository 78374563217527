import './style.scss';

import { Button } from 'react-bootstrap';

import { ModalDeleteProps } from './types';

const ModalDeleteContent = (props: ModalDeleteProps) => {
  const { actionName, targetName, data, onCancel, onApply } = props;

  return (
    <div className='modalDeleteScript'>
      <span className='modalDeleteScript__message modalDeleteScript__message_level_1'>
        {actionName}
        <br /> {targetName}
      </span>
      <span className='modalDeleteScript__message modalDeleteScript__message_level_2'>
        {data}
      </span>
      <div className='modalDeleteScript__controls'>
        <Button variant='outline-primary mb-2' onClick={onCancel}>
          Отмена
        </Button>
        <Button onClick={onApply}>Удалить</Button>
      </div>
    </div>
  );
};

export default ModalDeleteContent;
