// @ts-nocheck

function randomNumber() {
  return `${
    Math.floor(Math.random() * (10 - 0) + 0).toString() +
    Math.floor(Math.random() * (10 - 0) + 0).toString() +
    Math.floor(Math.random() * (10 - 0) + 0).toString() +
    Math.floor(Math.random() * (10 - 0) + 0).toString() +
    Math.floor(Math.random() * (10 - 0) + 0).toString()
  }`;
}

const chars = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'yo',
  ж: 'zh',
  з: 'z',
  и: 'i',
  й: 'y',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  c: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'c',
  ч: 'ch',
  ш: 'sh',
  щ: 'shch',
  ъ: '',
  ы: 'y',
  ь: '',
  э: 'e',
  ю: 'yu',
  я: 'ya',
  А: 'a',
  Б: 'b',
  В: 'v',
  Г: 'g',
  Д: 'd',
  Е: 'e',
  Ё: 'yo',
  Ж: 'zh',
  З: 'z',
  И: 'i',
  Й: 'y',
  К: 'k',
  Л: 'l',
  М: 'm',
  Н: 'n',
  О: 'o',
  П: 'p',
  Р: 'r',
  C: 's',
  Т: 't',
  У: 'u',
  Ф: 'f',
  Х: 'h',
  Ц: 'c',
  Ч: 'ch',
  Ш: 'sh',
  Щ: 'shch',
  Ъ: '',
  Ы: 'y',
  Ь: '',
  Э: 'e',
  Ю: 'yu',
  Я: 'ya',
};

export const useLoginGenerator = () => {
  const defLogin = `user${randomNumber()}`;

  const onGenerating = (fio: string) => {
    const fioAsArr = fio.split(' ');

    if (fioAsArr.length < 3) return defLogin;

    const newSurname = fioAsArr[0]
      .split('')
      .map((item) => chars[item])
      .join('');
    const newName = chars[fioAsArr[1]?.[0]];
    const newLastName = chars[fioAsArr[2]?.[0]];
    const newValue =
      !!newSurname && !!newLastName && !!newName
        ? `${newSurname}_${newName + newLastName}`
        : '';

    if (!newValue) return defLogin;
    return newValue;
  };

  return onGenerating;
};
