import { useUndoableContext } from 'contexts/undoable';
import { EdgeProps } from 'react-flow-renderer';

type Keys = keyof EdgeData;
type Values = EdgeData[Keys];

export function useUpdateEdge(id: EdgeProps['id']) {
  const { setElements } = useUndoableContext();
  const onUpdateEdgeData = (data: { [K in Keys]?: Values }) => {
    setElements((elements) => ({
      ...elements,
      edges: elements.edges?.map((edge) => ({
        ...edge,
        data: edge.id === id ? { ...edge.data, ...data } : edge.data,
      })),
    }));
  };

  return { onUpdateEdgeData };
}

export default useUpdateEdge;
