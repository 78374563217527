const customTotal = (from: number, to: number, size: number) => {
  return (
    <span className='react-bootstrap-table-pagination-total'>
      Показано c {from} по {to} из {size} результатов
    </span>
  );
};

export const getPaginationOptions = (options: PaginationOptions) => {
  return {
    nextPageText: 'Далее',
    prePageText: 'Назад',
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    ...options,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '20',
        value: 20,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
};
