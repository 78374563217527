import './style.scss';

import { Breadcrumbs, CardTable } from 'components';
import { ModalCreateUser } from 'modules';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import {
  ButtonCreateUser,
  ModalDeleteUser,
  TableRoles,
  TableUsers,
} from './components';

const Users = () => {
  return (
    <>
      <Breadcrumbs
        title='Пользователи'
        breadcrumbItems={['Пользователи', 'Список']}
      />
      <ModalCreateUser />
      <ModalDeleteUser />
      <Card className='users users__card'>
        <Tabs defaultActiveKey='users' id='users-tabs'>
          <Tab
            eventKey='users'
            title='Управление пользователями'
            className='users users__tab'
          >
            <CardTable>
              <TableUsers />
            </CardTable>
            <ButtonCreateUser />
          </Tab>
          <Tab eventKey='roles' title='Управление ролями'>
            <CardTable>
              <TableRoles />
            </CardTable>
          </Tab>
        </Tabs>
      </Card>
    </>
  );
};

export default Users;
