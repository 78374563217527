import { useUndoableContext } from 'contexts/undoable';
import { getConnectedEdges, Node } from 'react-flow-renderer';

export function useConnectedEdges() {
  const { elements } = useUndoableContext();

  const getEdgesByType = (node: Node) => {
    const edges = getConnectedEdges([node], elements.edges ?? []);

    const incoming = edges.filter((edge) => edge.target === node.id);
    const outgoing = edges.filter((edge) => edge.source === node.id);

    return {
      incoming,
      outgoing,
    };
  };
  return { getEdgesByType };
}

export default useConnectedEdges;
