import type Element from '@ckeditor/ckeditor5-engine/src/model/element';
import type Model from '@ckeditor/ckeditor5-engine/src/model/model';
import ClassicEditor from '@hyperscript/ckeditor5-build';

import { FormValues } from '../components/CustomAction/types';
import { Field } from '../components/CustomVariable/components/ModalContent/types';
import { Option } from '../components/CustomVariable/types';

export interface CustomVariableData {
  type: string;
  name: string;
  label?: string;
  items?: Option[];
  multi?: boolean;
}

export const CKEDITOR_VARIABLE_COMMAND_NAME = 'customVariable';

export const addCustomVariableToEditor = (
  editor: ClassicEditor,
  data: CustomVariableData
) => {
  editor.execute(CKEDITOR_VARIABLE_COMMAND_NAME, data);
};

export const CKEDITOR_ACTION_COMMAND_NAME = 'customAction';

export type CustomActionData = FormValues;
export const addCustomActionToEditor = (
  editor: ClassicEditor,
  body: CustomActionData
) => {
  const newData: Omit<CustomActionData, 'data'> & { data?: string } = {
    url: body.url,
    method: body.method,
    title: body.title,
  };

  if (body.data && body.method === 'POST') {
    newData.data = JSON.parse(body.data);
  }
  editor.execute(CKEDITOR_ACTION_COMMAND_NAME, newData);
};

const iterateCustomVariableFields = (element: Element): Element[] => {
  const result: Element[] = [];
  const childrens = element.getChildren();
  for (const item of childrens) {
    if (item.is('element')) {
      if (item.name === CKEDITOR_VARIABLE_COMMAND_NAME) {
        result.push(item);
      }
      if (item.childCount !== 0)
        result.push(...iterateCustomVariableFields(item));
    }
  }
  return result;
};

export const getCustomVariableFields = (
  model: Model,
  variableName?: string
): Element[] => {
  const result: Element[] = [];
  const document = model.document;
  const root = document.getRoot();
  if (root) {
    const rootChildrens = root.getChildren();
    for (const rootChildren of rootChildrens) {
      if (rootChildren.is('element')) {
        result.push(...iterateCustomVariableFields(rootChildren));
      }
    }
  }
  if (variableName)
    return result.filter(
      (element) => element.getAttribute('name') === variableName
    );
  return result;
};

const getItemsUnderSelection = (model: Model): Element[] => {
  const result: Element[] = [];
  const selectedItems = model.document.selection.getFirstRange()?.getItems();
  if (selectedItems) {
    for (const item of selectedItems) {
      if (item.is('element') && item.name === CKEDITOR_VARIABLE_COMMAND_NAME)
        result.push(item);
    }
  }
  return result;
};

export const changeVariableFields = (
  editor: ClassicEditor,
  newFields: Field[]
) => {
  const model = editor.model;
  const itemsUnderSelection = getItemsUnderSelection(model);
  const existingElements = getCustomVariableFields(model);

  for (const newField of newFields) {
    const isExist = existingElements.some(
      (item) => item.getAttribute('name') === newField.value
    );
    const isItemUnderDeletion = itemsUnderSelection.some(
      (item) => item.getAttribute('name') === newField.value
    );
    if (isExist && !isItemUnderDeletion) continue;
    addCustomVariableToEditor(editor, {
      name: newField.value,
      type: 'text',
      label: newField.label,
    });
  }
  const itemsToDelete: Element[] = [];
  for (const element of existingElements) {
    const elementName = element.getAttribute('name') as string | undefined;
    if (
      !elementName ||
      !!newFields.find((field) => field.value === elementName)
    )
      continue;

    itemsToDelete.push(element);
  }

  model.change((writer) => {
    for (const item of itemsToDelete) {
      writer.remove(item);
    }
  });
};
