import classNames from 'classnames';
import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { crmNamesSelector } from 'store/editor/selectors';
import { useAppSelector } from 'store/hooks';

interface Props {
  selectedTab: string;
  changeSelectedTab: (value: string) => void;
}

const SideNavigation: FC<Props> = ({ changeSelectedTab, selectedTab }) => {
  const crmNames = useAppSelector(crmNamesSelector, shallowEqual);

  return (
    <div className='customvariable__modal_side'>
      {crmNames.map((item, index) => (
        <button
          key={`${item.value}${index}`}
          className={classNames('customvariable__modal_side_item', {
            active: selectedTab === item.value,
          })}
          onClick={() => changeSelectedTab(item.value)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default SideNavigation;
