import { TYPE_EDGE, TYPE_NODE, TYPES_ARROW } from 'constants/graph';
import { useUndoableContext } from 'contexts/undoable';
import {
  applyEdgeChanges,
  applyNodeChanges,
  Edge,
  Node,
} from 'react-flow-renderer';
import { uuid } from 'utils';

export function useCreateStep() {
  const { setElements, getNode } = useUndoableContext();

  const onCreateStep = (params: {
    data: Edge;
    typeTargetField: 'source' | 'target';
    nodeParent: Node;
  }) => {
    const { data, typeTargetField, nodeParent } = params;
    data.id = uuid(); // так как '-1'
    const isNodeExist = getNode(data[typeTargetField]);

    if (!isNodeExist) {
      let position = nodeParent.position;

      position = { x: position.x + 450, y: position.y }; // 250 - примерно размер блока с отступом

      const childNode = {
        id: data[typeTargetField],
        type: TYPE_NODE,
        data: {
          // @ts-ignore
          label: data[data[typeTargetField]],
          description: '',
          purpose: false,
        },
        position,
      };

      const newEdge = {
        ...data,
        type: TYPE_EDGE,
        // @ts-ignore
        ...TYPES_ARROW[data.type || 'neutral'],
      };

      setElements((elements) => ({
        ...elements,
        nodes: elements.nodes
          ? applyNodeChanges([{ item: childNode, type: 'add' }], elements.nodes)
          : elements.nodes,
        edges: elements.edges
          ? applyEdgeChanges([{ item: newEdge, type: 'add' }], elements.edges)
          : elements.edges,
      }));
    } else {
      setElements((elements) => ({
        ...elements,
        edges: elements.edges
          ? applyEdgeChanges([{ item: data, type: 'add' }], elements.edges)
          : elements.edges,
      }));
    }
  };

  return { onCreateStep };
}

export default useCreateStep;
