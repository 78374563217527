import { useEvent } from 'hooks/useEvent';
import { isEqual } from 'lodash-es';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { Edge, Node } from 'react-flow-renderer';
import { CrmEntity, InitialScript } from 'store/editor/types';
import useUndoable from 'use-undoable';

export interface UndoableContextProps {
  children: React.ReactNode;
  initialScript: InitialScript;
}

export interface UndoableState {
  nodes?: Node[];
  edges?: Edge[];
  css?: string;
  crmKeys?: Record<string, CrmEntity['key']>;
}

export interface UndoableContextValues {
  elements: UndoableState;
  isChanged: boolean;
  setElements: (
    payload: UndoableState | ((oldValue: UndoableState) => UndoableState),
    behavior?:
      | 'mergePastReversed'
      | 'mergePast'
      | 'destroyFuture'
      | 'keepFuture'
      | undefined,
    ignoreAction?: boolean | undefined
  ) => void;
  undo: () => void;
  canUndo: boolean;
  redo: () => void;
  canRedo: boolean;
  getNode: (arg0: string) => Node | undefined;
  getEdge: (arg0: string) => Edge | undefined;
  getSelectedNode: () => Node | undefined;
  getSelectedEdge: () => Edge | undefined;
  save: () => void;
}

const UndoableContext = createContext({} as UndoableContextValues);

export const UndoableContextProvider: React.FC<UndoableContextProps> = ({
  children,
  initialScript,
}) => {
  const [elements, setElementsSetter, { undo, canUndo, redo, canRedo }] =
    useUndoable(initialScript);

  const [savedElements, setSavedElements] = useState(initialScript);
  // setElementsSetter not like setter in useState; he dont provide actual state in prev: setState(prev => prev)
  const setElements = useEvent(setElementsSetter);

  const getNode = useCallback(
    (id: string) => elements.nodes?.find((el) => el.id === id),
    [elements.nodes]
  );

  const getEdge = useCallback(
    (id: string) => elements.edges?.find((el) => el.id === id),
    [elements.edges]
  );

  const getSelectedNode = useCallback(
    () => elements.nodes?.find((el) => el.selected),
    [elements.nodes]
  );

  const getSelectedEdge = useCallback(
    () => elements.edges?.find((el) => el.selected),
    [elements.edges]
  );

  const save = useCallback(() => {
    setSavedElements(elements);
  }, [elements]);

  const isChanged = !isEqual(elements, savedElements);

  return (
    <UndoableContext.Provider
      value={{
        elements,
        setElements,
        undo,
        canUndo,
        redo,
        canRedo,
        getNode,
        getEdge,
        getSelectedNode,
        getSelectedEdge,
        save,
        isChanged,
      }}
    >
      {children}
    </UndoableContext.Provider>
  );
};

export const useUndoableContext = () => useContext(UndoableContext);
