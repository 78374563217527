import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { getQueryTable } from './selectors';
import RolesService from './service';

export const fetchRoles = createAsyncThunk(
  'roles/fetchRoles',
  async (_, { rejectWithValue, getState }) => {
    try {
      const queryTable = getQueryTable(getState());
      const response = await RolesService.fetchRoles({ query: queryTable });

      return response.data.data;
    } catch (error) {
      let errorMessage = 'Неизвестная проблема';
      if (error instanceof AxiosError) {
        errorMessage = error.message;
        if (error.response?.data) {
          errorMessage = '';
        }
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const setTableConfig = createAction<any>('roles/setTableConfig');

export const setPagination = createAsyncThunk(
  'roles/setPagination',
  async (tableConfigParameter: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(setTableConfig(tableConfigParameter));
      thunkAPI.dispatch(fetchRoles());

      return tableConfigParameter;
    } catch (error) {}
  }
);

// export const setPagination = createAction<any>('roles/setPagination');
