import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const userState = (state: RootState) => state.user;

export const getUserData = createSelector(userState, (state) => {
  if (state.user) return state.user;

  return {
    id_user: 0,
    fio: '',
    login: '',
    id_role: 0,
    role: '',
    created_at: '',
    pages: [],
  };
});

export const getUserPagesSelector = (state: RootState) =>
  state.user.user?.pages ?? [];

export const getUserSelector = (state: RootState) => state.user;
