import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

import { ScriptsState } from './types';

export const getScriptsState = (state: RootState): ScriptsState =>
  state.scripts;

export const getSelectedScript = createSelector(getScriptsState, (state) => {
  const scriptData = state.scripts.find(
    (script) => script.id_script === state.modal.targetId
  );

  return (
    scriptData || {
      id_script: 0,
      name: '',
      goal: '',
    }
  );
});

// toDo
export const getQueryTable = (state: any) => {
  const tableConfig = state.scripts.tableConfig;
  const searchConfig = state.scripts.searchBy;
  const [keyFieldSort, typeFieldSort] = Object.entries(tableConfig.sortBy)[0];

  const queryObj = {
    count: tableConfig.size,
    page: tableConfig.page,
    sort_by: keyFieldSort,
    sort_type: typeFieldSort,
    search: { ...searchConfig },
  };

  return queryObj;
};

export const getIsScriptEdited = (id: number) =>
  createSelector(
    getScriptsState,
    (scriptsState) => !!scriptsState.rowsEditing[id]
  );

export const getEditedScript = (id: number) =>
  createSelector(
    getScriptsState,
    (scriptsState) => scriptsState.rowsEditing[id]
  );
