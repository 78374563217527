import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { setEditorScriptId } from 'store/editor/actions';
import { setStatus } from 'store/statusApp/actions';

import { getQueryTable } from './selectors';
import ScriptsService from './service';
import { ScriptUpdateConfig, ScriptСreationConfig } from './types';

export const setModal = createAction<ModalConfig>('scripts/modal');

export const fetchScript = createAsyncThunk(
  'scripts/fetchScript',
  async (_: void, thunkAPI) => {
    try {
      // @ts-ignore
      const queryTable = getQueryTable(thunkAPI.getState());
      const response = await ScriptsService.fetchScripts({
        query: queryTable,
      });

      return response.data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          const errorMessage = error.response.data.message;

          thunkAPI.dispatch(
            setStatus({ status: 'danger', message: errorMessage })
          );
          return thunkAPI.rejectWithValue(errorMessage);
        }
        thunkAPI.dispatch(
          setStatus({ status: 'danger', message: error.message })
        );
        return thunkAPI.rejectWithValue(error.message);
      }
      thunkAPI.dispatch(
        setStatus({ status: 'warning', message: 'Неизвестная проблема' })
      );
      return thunkAPI.rejectWithValue('Неизвестная проблема');
    }
  }
);

export const createScript = createAsyncThunk(
  'scripts/createScript',
  async (scriptData: ScriptСreationConfig, thunkAPI) => {
    try {
      const response = await ScriptsService.createScript(scriptData);
      const idCreatedScript = response.data.data[0].id;
      thunkAPI.dispatch(fetchScript());
      thunkAPI.dispatch(setEditorScriptId({ scriptId: idCreatedScript }));

      return scriptData;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          thunkAPI.dispatch(
            setStatus({
              status: 'danger',
              message: error.response.data.message,
            })
          );
          return thunkAPI.rejectWithValue(scriptData); // ToDo
          // return thunkAPI.rejectWithValue(error.response.data.message);
        }
        thunkAPI.dispatch(
          setStatus({ status: 'danger', message: error.message })
        );
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue('Неизвестная проблема');
    }
  }
);

export const updateScript = createAsyncThunk(
  'scripts/updateScript',
  async (scriptData: ScriptUpdateConfig, thunkAPI) => {
    try {
      const response = await ScriptsService.updateScript(scriptData);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          thunkAPI.dispatch(
            setStatus({
              status: 'danger',
              message: error.response.data.message,
            })
          );
          return thunkAPI.rejectWithValue(scriptData); // ToDo
          // return thunkAPI.rejectWithValue(error.response.data.message);
        }
        thunkAPI.dispatch(
          setStatus({ status: 'danger', message: error.message })
        );
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue('Неизвестная проблема');
    }
  }
);

export const deleteScript = createAsyncThunk(
  'scripts/deleteScript',
  async (script: { scriptId: number }, thunkAPI) => {
    try {
      await ScriptsService.deleteScript(script.scriptId);
      thunkAPI.dispatch(fetchScript());
      return script;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          thunkAPI.dispatch(
            setStatus({
              status: 'danger',
              message: error.response.data.message,
            })
          );
          return thunkAPI.rejectWithValue(script); // ToDo
          // return thunkAPI.rejectWithValue(error.response.data.message);
        }
        thunkAPI.dispatch(
          setStatus({ status: 'danger', message: error.message })
        );
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue('Неизвестная проблема');
    }
  }
);

export const copyScript = createAsyncThunk(
  'scripts/copyScript',
  async (script: { scriptId: number }, thunkAPI) => {
    try {
      const response = await ScriptsService.copyScript(script.scriptId);
      thunkAPI.dispatch(
        setStatus({ status: 'success', message: response.data.message })
      );
      thunkAPI.dispatch(fetchScript());
      return script;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          thunkAPI.dispatch(
            setStatus({
              status: 'danger',
              message: error.response.data.message,
            })
          );
          // return thunkAPI.rejectWithValue(error.response.data.message);
        }
        setStatus({
          status: 'danger',
          message: 'Неизвестная проблема при копировании',
        });

        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue('Неизвестная проблема');
    }
  }
);

export const setTableConfig = createAction<any>('scripts/setTableConfig');
export const setPagination = createAsyncThunk(
  'scripts/setPagination',
  async (tableConfigParameter: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(setTableConfig(tableConfigParameter));
      thunkAPI.dispatch(fetchScript());

      return tableConfigParameter;
    } catch (error) {}
  }
);

export const setSearchConfig = createAction<any>('scripts/setSearchConfig');
export const setSearch = createAsyncThunk(
  'scripts/setSearch',
  async (searchConfig: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(setSearchConfig(searchConfig));
      thunkAPI.dispatch(fetchScript());

      return searchConfig;
    } catch (error) {}
  }
);

// export const setPagination = createAction<any>('scripts/setPagination');
