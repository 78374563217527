import './style.scss';

import ButtonCreateScript from '../ButtonCreateScript';

const NoScripts = () => (
  <div className='modalNoScripts'>
    <div className='modalNoScripts__content'>
      <span className='modalNoScripts__message'>
        Сейчас у вас нет ни одного скрипта, <br />
        Вы можете создать свой скрипт
      </span>
      <ButtonCreateScript text='Создать скрипт' />
    </div>
  </div>
);

export default NoScripts;
