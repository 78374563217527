import { EditableCell } from 'modules';
import { getIsScriptEdited } from 'store/scripts/selectors';
import { ScriptConfig } from 'store/scripts/types';

import Controls from '../Controls';

type sortFunc = (column: string, order: string) => void;
interface paramsColumns {
  sortFunc: sortFunc;
  size: number;
}

export const getColumnsScriptsTable = (params: paramsColumns) => [
  {
    dataField: 'id_script',
    text: '',
    hidden: true,
  },
  {
    dataField: 'number',
    headerClasses: 'col-1',
    text: '№',
    formatter: (_: string, __: ScriptConfig, index: number) => {
      return <span>{index + 1}</span>;
    },
  },
  {
    dataField: 'name',
    text: 'Название скрипта',
    sort: true,
    onSort: params.sortFunc,
    edit: true,
    formatter: (value: string, row: ScriptConfig) => (
      <EditableCell
        initValue={value}
        id={row.id_script}
        name='name'
        isEditSelector={getIsScriptEdited}
      />
    ),
  },
  {
    dataField: 'goal',
    text: 'Цель скрипта',
    sort: true,
    onSort: params.sortFunc,
    edit: true,
    formatter: (value: string, row: ScriptConfig) => (
      <EditableCell
        initValue={value}
        id={row.id_script}
        name='goal'
        isEditSelector={getIsScriptEdited}
      />
    ),
  },
  {
    dataField: 'created_at',
    text: 'Дата создания',
    sort: true,
    onSort: params.sortFunc,
  },

  {
    dataField: 'controls',
    headerClasses: 'col-3',
    text: '',
    sort: false,
    formatter: (_: string, row: ScriptConfig) => (
      <Controls script={row} formId={row.form_key} />
    ),
  },
];
