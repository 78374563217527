import classNames from 'classnames';
import { FC } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { CrmEntityFields } from 'store/editor/types';

import { Field, SelectedValues } from '../ModalContent/types';
import SelectFieldButton from '../SelectFieldButton/SelectFieldButton';

interface Props {
  values: SelectedValues;
  selectField: (field: Field) => void;
  removeField: (field: Field) => void;
  crm: CrmEntityFields;
  crmKey: string;
}

const CrmVariableTab: FC<Props> = ({
  values,
  removeField,
  selectField,
  crmKey,
  crm,
}) => {
  const { crm_name: crmDisplayName, ...fieldTypes } = crm;
  const isFieldSelectedHandler = (value: string) =>
    !!values.find((item) => item.value === value);

  const onClick = (isFieldSelected: boolean, field: Field) => () =>
    isFieldSelected ? removeField(field) : selectField(field);

  return (
    <div className='customvariable__modal_tab_suite'>
      <div className='customvariable__modal_tab_suite_title'>
        Добавьте поля из {crmDisplayName}
      </div>
      <Tabs>
        {Object.entries(fieldTypes).map(([fieldTypeKey, item]) => (
          <Tab
            key={fieldTypeKey}
            title={item.module_name}
            eventKey={fieldTypeKey}
            className='suite__tab'
          >
            {Object.entries(item.fields).map(([fieldKey, fieldDisplayName]) => {
              const fieldConstructedName = `${crmKey}:${fieldTypeKey}:${fieldKey}`;
              const isFieldSelected =
                isFieldSelectedHandler(fieldConstructedName);
              return (
                <SelectFieldButton
                  key={fieldKey}
                  className={classNames({
                    added: isFieldSelected,
                  })}
                  onClick={onClick(isFieldSelected, {
                    value: fieldConstructedName,
                    label: fieldDisplayName,
                  })}
                >
                  {fieldDisplayName}
                </SelectFieldButton>
              );
            })}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};
export default CrmVariableTab;
