import { AxiosResponse } from 'axios';
import { ENDPOINTS } from 'constants/api_endpoints';
import { IResponse } from 'models/response';
import axiosApi from 'utils/api_helper';

import { AllCrmFields, InitialScript } from './types';

export default class EditorService {
  static async fetchEditorScript(editor: { scriptId: number }) {
    const response: AxiosResponse<IResponse<InitialScript>> =
      await axiosApi.get(`${ENDPOINTS.EDITOR_FETCH}/${editor.scriptId}`);

    return response;
  }

  static async updateEditorScript(scriptData: {
    scriptId: number;
    data: InitialScript;
  }) {
    const response: AxiosResponse<IResponse<any>> = await axiosApi.put(
      `${ENDPOINTS.EDITOR_FETCH}/${scriptData.scriptId}`,
      { data: scriptData.data }
    );

    return response;
  }

  static async createFormByScript(scriptData: { scriptId: number }) {
    const response: AxiosResponse<IResponse<{ 'API-key': string }[]>> =
      await axiosApi.post(`${ENDPOINTS.FORMS}`, {
        id_script: scriptData.scriptId,
        name: `Форма по скрипту ${scriptData.scriptId}`,
        is_all_user: true,
      });

    return response;
  }

  static async fetchFormByScript(formData: { formId: string }) {
    const response: AxiosResponse<any> = await axiosApi.get(
      `${ENDPOINTS.FORMS}/${formData.formId}`,
      {
        responseType: 'blob',
      }
    );

    return response;
  }

  static async fetchCrmFields(): Promise<AllCrmFields> {
    const response: AxiosResponse<{ data: AllCrmFields }> = await axiosApi.get(
      `${ENDPOINTS.GET_CRM_FIELDS}`
    );
    return response.data.data;
  }
}
