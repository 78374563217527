/* 
  id_role: number;
  role: string;
*/

import { RoleConfig } from 'store/roles/types';

type sortFunc = (column: string, order: string) => void;
interface paramsColumns {
  sortFunc: sortFunc;
  size: number;
}

export const getTableRolesColumns = (params: paramsColumns) => [
  {
    dataField: 'id_role',
    text: '',
    hidden: true,
  },
  {
    dataField: 'number',
    text: '№',
    formatter: (_: string, __: RoleConfig, index: number) => {
      return <span>{index + 1}</span>;
    },
  },
  {
    dataField: 'role',
    text: 'Название роли',
    sort: true,
    onSort: params.sortFunc,
  },
  {
    dataField: 'powers',
    text: 'Полномочия',
  },
  {
    dataField: 'controls',
    headerClasses: 'col-6',
    text: '',
    sort: false,
  },
];
