import { Breadcrumbs, CardTable } from 'components';

import {
  ContentPage,
  ModalCreateScript,
  ModalDeleteScript,
} from './components';

const Scripts = () => {
  return (
    <>
      <Breadcrumbs title='скрипты' breadcrumbItems={['Cкрипты', 'Список']} />
      <CardTable>
        <ContentPage />
        <ModalCreateScript />
        <ModalDeleteScript />
      </CardTable>
    </>
  );
};

export default Scripts;
