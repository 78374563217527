import './style.scss';

import classNames from 'classnames';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

type Props = HTMLAttributes<HTMLButtonElement> & PropsWithChildren;

const SelectFieldButton: FC<Props> = ({
  className = '',
  children,
  ...props
}) => {
  return (
    <button className={classNames('select__field_btn', className)} {...props}>
      {children}
    </button>
  );
};
export default SelectFieldButton;
