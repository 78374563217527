import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import { basename } from 'constants/app_constants';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store/store';

import App from './App';

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  // <StrictMode>
  <BrowserRouter basename={basename}>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
  // </StrictMode>
);
