import { useUndoableContext } from 'contexts/undoable';
import { applyEdgeChanges, EdgeProps } from 'react-flow-renderer';

export function useRemoveEdge(id: EdgeProps['id']) {
  const { setElements, getEdge } = useUndoableContext();
  const onClick = () => {
    const edge = getEdge(id);

    if (!edge) {
      return;
    }

    setElements((elements) => ({
      ...elements,
      edges: elements.edges
        ? applyEdgeChanges([{ id: id, type: 'remove' }], elements.edges)
        : elements.edges,
    }));
  };

  return onClick;
}

export default useRemoveEdge;
