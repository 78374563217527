import './style.scss';

import { Prompt } from 'components';
import { DEF_EDGE_DATA, TYPE_EDGE, TYPES_ARROW } from 'constants/graph';
import { useUndoableContext } from 'contexts/undoable';
import { useSelectingStep } from 'hooks';
import useAddEdge from 'hooks/useAddEdge';
import { MouseEvent as ReactMouseEvent, useCallback } from 'react';
import Flow, {
  Background,
  Controls,
  Edge,
  Node,
  ProOptions,
  ReactFlowProps,
  ReactFlowProvider,
} from 'react-flow-renderer';
import { ControlButton } from 'reactflow';

import { ButtonSave, ConnectionLine, Sidebar } from './components';
import { edgeTypes, nodeTypes } from './connector';

const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };

export const Graph = (_props: Partial<ReactFlowProps>): JSX.Element => {
  const {
    elements,
    setElements,
    undo,
    redo,
    canUndo,
    canRedo,
    getSelectedNode,
    getSelectedEdge,
    isChanged,
    save,
  } = useUndoableContext();

  const addEdge = useAddEdge();
  const { onSelectingNode, onSelectingStep, onUnselectAll } =
    useSelectingStep();

  const onEdgeClick = (_: ReactMouseEvent, edge: Edge) => {
    if (edge) {
      onSelectingStep({ nodeId: edge.source, edgeId: edge.id });
    }
  };

  const onNodeClick = (_: ReactMouseEvent, node: Node) => {
    if (node) {
      onSelectingNode(node.id);
    }
  };

  const onPaneClick = () => {
    onUnselectAll();
  };

  const callbackAfterSave = () => {
    save();
  };

  const onDragStop = useCallback(
    (_: ReactMouseEvent, node: Node) => {
      setElements((currentElements) => ({
        ...currentElements,
        nodes: currentElements.nodes?.map((currentElement) => ({
          ...currentElement,
          selected: currentElement.id === node.id,
          position:
            currentElement.id === node.id
              ? node.position
              : currentElement.position,
        })),
      }));
    },
    [setElements]
  );

  const selectedNodeId = getSelectedNode()?.id;
  // key указывается, чтобы сайдбар размаунтился и заново отрендерился при смене ноды, иначе начинает анмаунтиться редактор, а он крашится в таком случае и
  // из-за этого перевыбор ноды внешне отменяется, а содержимое редактора пропадает
  const sidebarKey = selectedNodeId ? { key: `sidebar-${selectedNodeId}` } : {};

  return (
    <>
      <Prompt
        message='Возможно, внесенные изменения не сохранятся.'
        when={isChanged}
      />
      <Flow
        defaultNodes={elements.nodes}
        defaultEdges={elements.edges}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        className='touchdevice-flow'
        onPaneClick={onPaneClick}
        onNodeClick={onNodeClick}
        onEdgeClick={onEdgeClick}
        defaultEdgeOptions={{
          type: TYPE_EDGE,
          data: DEF_EDGE_DATA,
          ...TYPES_ARROW.neutral,
        }}
        connectionLineComponent={ConnectionLine}
        onNodeDragStop={onDragStop}
        onConnect={(edge) => addEdge(edge as Edge<EdgeData>)}
      >
        <Background />
        <Sidebar
          {...sidebarKey}
          selectedNodeId={selectedNodeId}
          selectedEdgeId={getSelectedEdge()?.id}
        />
        {isChanged && <ButtonSave callbackAfterSave={callbackAfterSave} />}
        <Controls showInteractive={false}>
          <ControlButton
            onClick={() => undo()}
            className='react-flow__controls-button--first'
            disabled={!canUndo}
          >
            <i className='bx bx-undo' />
          </ControlButton>
          <ControlButton
            onClick={() => redo()}
            className='react-flow__controls-button--first'
            disabled={!canRedo}
          >
            <i className='bx bx-redo' />
          </ControlButton>
        </Controls>
      </Flow>
    </>
  );
};

export default (props: Partial<ReactFlowProps>) => (
  <ReactFlowProvider>
    <Graph {...props} />
  </ReactFlowProvider>
);
