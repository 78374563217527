import './style.scss';

import { ChangeEvent, useEffect, useState } from 'react';

import { LIST_STATUSES } from './constants';
import { StatusOptionsProps } from './types';

function Option(props: {
  type: string;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}) {
  const { type, label, checked, onChange } = props;

  return (
    <label className={`statusOptions__label   statusOptions__label_${type}`}>
      <input
        checked={checked}
        onChange={onChange}
        className='form-check-input'
        type='checkbox'
        data-type={type}
      ></input>
      <span>{label}</span>
    </label>
  );
}

function StatusOptions(props: StatusOptionsProps) {
  const { name, defaultValue, onChangeState, updateValue } = props;
  const [selectedType, setSelectedType] = useState<string>(defaultValue);
  const idComponent = `group_${name}`;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = event.target.dataset.type || 'neutral';
    onChangeState({ [name]: type });
    setSelectedType(type);
  };

  useEffect(() => {
    if (updateValue) {
      updateValue(selectedType, setSelectedType);
    }
  }, [selectedType, updateValue]);

  return (
    <label htmlFor={idComponent} className='statusOptions'>
      Статус перехода на следующий шаг:
      <div
        id={idComponent}
        role='group'
        className='mb-3 statusOptions__content'
      >
        {LIST_STATUSES.map((status) => (
          <Option
            key={status.key}
            type={status.key}
            label={status.label}
            onChange={onChange}
            checked={selectedType === status.key}
          />
        ))}
      </div>
    </label>
  );
}

export default StatusOptions;
