import './style.scss';

import { useButtonAddNode, useRemoveNode } from 'hooks';
import { memo } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';

const Node = (props: NodeProps) => {
  const { id, data } = props;

  const onClickAdd = useButtonAddNode(id);
  const onRemoveNode = useRemoveNode(id);

  return (
    <>
      <Handle type='target' position={Position.Top} />
      <div className={`${data.purpose && 'node_purpose'} node`}>
        <p style={{ fontSize: '13px' }}>{data.label}</p>
        <div className='node__button__wrapper'>
          <button
            className='node__button node__button_add'
            onClick={(e) => {
              e.stopPropagation();
              onClickAdd();
            }}
          >
            +
          </button>
          <button
            className='node__button node__button_remove'
            onClick={(e) => {
              e.stopPropagation();
              onRemoveNode();
            }}
          >
            ×
          </button>
        </div>
      </div>
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export default memo(Node);
