import { AnySchema, object, string } from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';

import { FormValues } from './types';

const checkIsDataValidJson = (data: string): boolean => {
  try {
    JSON.parse(data);
    return true;
  } catch {
    return false;
  }
};

export const CustomActionValidation: OptionalObjectSchema<
  Record<keyof FormValues, AnySchema>
> = object({
  data: string().test({
    name: 'isValidJson',
    message: 'Поле должно быть валидным JSON',
    test(value, ctx) {
      const values = ctx.parent as FormValues;
      if (values.method === 'GET') return true;
      if (!value) return true;
      return checkIsDataValidJson(value);
    },
    exclusive: true,
  }),
  method: string().required(),
  title: string().required(),
  url: string().required(),
});
