import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { nameToken } from 'constants/app_constants';

import AuthService from './service';
import { AuthLoginParams } from './types';

export const login = createAsyncThunk(
  'auth/login',
  async (authParams: AuthLoginParams, thunkAPI) => {
    try {
      const response = await AuthService.login(authParams);

      localStorage.setItem(
        nameToken,
        `Bearer ${response.data.data.access_token}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          return thunkAPI.rejectWithValue(error.response.data.message);
        }
        return thunkAPI.rejectWithValue('Проблема сети');
      }
      return thunkAPI.rejectWithValue('Неизвестная проблема');
    }
  }
);
export const logout = createAsyncThunk(
  'auth/logout',
  async (_: void, thunkAPI) => {
    try {
      const response = await AuthService.logout();
      localStorage.removeItem(nameToken);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          return thunkAPI.rejectWithValue(error.response.data.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue('Неизвестная проблема');
    }
  }
);
