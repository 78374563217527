import './style.scss';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@hyperscript/ckeditor5-build';
import { SERVER_URL } from 'constants/api_endpoints';
import { useDebounce } from 'hooks/useDebounce';
import { memo, useState } from 'react';

import CustomAction from './components/CustomAction/CustomAction';
import CustomVariable from './components/CustomVariable/CustomVariable';
import { EditorProps } from './types';

const editorConfiguration = {
  mediaEmbed: {
    previewsInData: true,
  },
  toolbar: [
    'heading',
    // '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    // '|',
    'outdent',
    'indent',
    // '|',
    'uploadImage',
    'blockQuote',
    'insertTable',
    'mediaEmbed',
    'customAction',
    'customVariable',
    // '|',
    'undo',
    'redo',
  ],
  simpleUpload: {
    uploadUrl: `${SERVER_URL}/exchange/upload_img`,
  },
};

const Editor = (props: EditorProps) => {
  const [editor, setEditor] = useState<ClassicEditor | null>(null);
  const { name, defaultValue, onChangeState } = props;

  const onChangeText = useDebounce((editorInstance: ClassicEditor) => {
    const text = editorInstance.getData();
    if (text === defaultValue) return;
    const data = { [name]: text };
    onChangeState(data);
  }, 600);

  return (
    <div className='sidebar__editor'>
      <CKEditor
        id={name}
        editor={ClassicEditor}
        config={editorConfiguration}
        data={defaultValue}
        onReady={(editorInstance) => {
          setEditor(editorInstance);
        }}
        onChange={(_, editorInstance) => {
          onChangeText(editorInstance);
        }}
      />
      <CustomAction editor={editor} />
      <CustomVariable editor={editor} />
    </div>
  );
};

export default memo(Editor);
