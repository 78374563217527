import { AxiosError, AxiosResponse } from 'axios';
import { ENDPOINTS } from 'constants/api_endpoints';
import { useState } from 'react';
import { selctedScriptIdSelector } from 'store/editor/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setStatus } from 'store/statusApp/actions';
import axiosApi from 'utils/api_helper';

import { FormValues } from './SuiteCrmIntegration';

interface SendCrmAuthRequestData {
  id_script: number;
  key_crm: string;
  url: string;
  username: string;
  password: string;
  client_id?: string;
  client_secret?: string;
}

export const useSendSuiteCrmAuth = (): [
  (data: FormValues) => Promise<AxiosResponse | undefined>,
  boolean
] => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const scriptId = useAppSelector(selctedScriptIdSelector);

  const sendAuth = async (data: FormValues) => {
    if (scriptId) {
      try {
        setLoading(true);
        const requestBody: SendCrmAuthRequestData = {
          password: data.password,
          url: data.url,
          username: data.login,
          client_id: data.client_id,
          client_secret: data.client_secret,
          id_script: scriptId,
          key_crm: 'suite',
        };
        const response = await axiosApi.post(
          `${ENDPOINTS.CRM_CREATE_AUTH}`,
          requestBody
        );
        dispatch(
          setStatus({
            status: 'success',
            message: 'Данные авторизации успешно сохранены',
          })
        );
        return response;
      } catch (error) {
        if (error instanceof AxiosError) {
          dispatch(
            setStatus({
              status: 'danger',
              message: 'Проверьте данные авторизации',
            })
          );
        }
        throw error;
      } finally {
        setLoading(false);
      }
    }
  };

  return [sendAuth, loading];
};
