import { configureStore } from '@reduxjs/toolkit';

import authSlice from './auth/slice';
import editorSlice from './editor/slice';
import StateLoader from './loader';
import rolesSlice from './roles/slice';
import scriptsSlice from './scripts/slice';
import statusAppSlice from './statusApp/slice';
import userSlice from './user/slice';
import usersSlice from './users/slice';

const stateLoader = new StateLoader();
export const store = configureStore({
  reducer: {
    auth: authSlice,
    users: usersSlice,
    roles: rolesSlice,
    scripts: scriptsSlice,
    user: userSlice,
    statusApp: statusAppSlice,
    editor: editorSlice,
  },
});

store.subscribe(() => {
  stateLoader.saveState(store.getState());
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
