import './style.scss';

import { ErrorMessageForm } from 'components';
import { Field, Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createUser } from 'store/users/actions';
import { getSelectedUser } from 'store/users/selectors';
import { UserConfig } from 'store/users/types';

import ButtonGenerationPassword from '../ButtonGenerationPassword';
import FieldFIO from '../FieldFIO';
import useCloseModal from '../hooks/useCloseModal';
import SelectRoles from '../SelectRoles';
import { validationsSchema } from './validations';

function FormCreateUser() {
  const closeModal = useCloseModal();
  const userData = useAppSelector(getSelectedUser);
  const initialValues: UserConfig = userData;

  const dispatch = useAppDispatch();

  const onReset = () => {
    closeModal();
  };

  const onApply = (values: UserConfig) => {
    dispatch(createUser(values));
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onApply}
        onReset={onReset}
        validationSchema={validationsSchema}
      >
        {() => (
          <Form className='formCreateUser'>
            <div className='formCreateUser__item'>
              <label htmlFor='fio'>ФИО:</label>
              {initialValues.login ? (
                <Field id='fio' name='fio' className='form-control' />
              ) : (
                <FieldFIO fieldName='fio' fieldNameWithLogin='login' />
              )}
              <ErrorMessageForm name='fio' />
            </div>

            <div className='formCreateUser__item'>
              <label htmlFor='id_role'>Выбор роли:</label>
              <SelectRoles fieldName='id_role' />
              <ErrorMessageForm name='id_role' />
            </div>

            <div className='formCreateUser__item'>
              <label htmlFor='login'>Логин:</label>
              <Field
                id='login'
                name='login'
                className='form-control'
                disabled={!!initialValues.login}
              />
              <ErrorMessageForm name='login' />
            </div>

            <div className='formCreateUser__item'>
              <label htmlFor='password'>Пароль:</label>
              <Row>
                <Col>
                  <Field
                    id='password'
                    name='password'
                    className='form-control'
                  />
                  <ErrorMessageForm name='password' />
                </Col>
                <Col>
                  <ButtonGenerationPassword
                    className='formCreateUser__button formCreateUser__button_password'
                    fieldName='password'
                  />
                </Col>
              </Row>
            </div>
            <br />
            <div className='formCreateUser__controls'>
              <Button
                type='reset'
                variant='outline-primary'
                className='formCreateUser__button'
              >
                Отмена
              </Button>
              <Button type='submit' className='formCreateUser__button'>
                Сохранить
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default FormCreateUser;
