import axios from 'axios';
import { CODES_ACCESS_IS_DENIED } from 'constants/api_constants';
import { ENDPOINTS, SERVER_URL } from 'constants/api_endpoints';
import { PUBLIC_ROUTES } from 'navigation/index';

import { getPathWithBasename } from './app_helper';

export const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const axiosApi = axios.create({
  withCredentials: true,
  baseURL: SERVER_URL,
});

axiosApi.interceptors.request.use((config) => {
  if (config.headers) {
    // config.headers.Authorization = `Bearer ${localStorage.getItem(nameToken)}`;
    config.headers['Content-Type'] = 'application/json';
  }

  return config;
});

const refreshToken = async () =>
  await axiosApi.get(ENDPOINTS.AUTH_REFRESH, {
    withCredentials: true,
  });

let isNeedRefreshToken = true;
let failedQueue: {
  reject: (value: unknown) => void;
  resolve: (value: unknown) => void;
}[] = [];

const processQueue = (error: unknown, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosApi.interceptors.response.use(
  (config) => {
    return config;
  },

  async (error) => {
    const loginPath = getPathWithBasename(PUBLIC_ROUTES.LOGIN.pathTransition);
    const originalRequest = error.config;

    if (
      CODES_ACCESS_IS_DENIED.includes(error.response.status) &&
      error.config &&
      !error.config._retry
    ) {
      if (isNeedRefreshToken === false) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
          if (
            originalRequest.url === `${SERVER_URL}/${ENDPOINTS.AUTH_REFRESH}` &&
            window.location.pathname !== loginPath
          ) {
            document.cookie = `Authorization=; max-age=- (any digit); path=/; domain=${window.location.hostname}`;
            window.location.href = loginPath;
          }
        })
          .then(() => {
            return axiosApi(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isNeedRefreshToken = false;
      return new Promise(function (resolve, reject) {
        refreshToken()
          .then(() => {
            processQueue(null);
            resolve(axiosApi(error.config));
          })
          .catch((err) => {
            // document.cookie = 'Authorization=; max-age=- (any digit); path=/;';
            document.cookie = `Authorization=; max-age=- (any digit); path=/; domain=${window.location.hostname}`;
            if (window.location.pathname !== loginPath) {
              window.location.href = loginPath;
            }

            // eslint-disable-next-line no-console
            console.log('НЕ АВТОРИЗОВАН');
            processQueue(err);
            reject(err);
          })
          .finally(() => {
            isNeedRefreshToken = true;
          });
      });
    }

    throw error;
  }
);

export default axiosApi;
