import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const scriptsStateSelector = (state: RootState) => state.scripts;

export const getSelectedScript = createSelector(
  scriptsStateSelector,
  (state) => {
    const scriptData = state.scripts.find(
      (script) => script.id_script === state.modal.targetId
    );

    if (scriptData) return scriptData;

    return {
      id_script: 0,
      name: '',
      goal: '',
    };
  }
);

export const editorStateSelector = (state: RootState) => state.editor;

export const selectedScriptSelector = createDraftSafeSelector(
  editorStateSelector,
  scriptsStateSelector,
  (editorState, scriptsState) => {
    const selectedScript = scriptsState.scripts.find(
      (script) => script.id_script === editorState.selectedEditorScriptId
    );
    return selectedScript;
  }
);

export const crmFieldsSelector = (state: RootState) => state.editor.crmFields;
export const selctedScriptIdSelector = (state: RootState) =>
  state.editor.selectedEditorScriptId;

export const crmNamesSelector = (state: RootState) => {
  const crmFields = state.editor.crmFields;
  return Object.entries(crmFields).map(([key, value]) => ({
    value: key,
    label: value.crm_name,
  }));
};
