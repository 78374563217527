import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setStatus } from './actions';

const initialState: StatusAppState = {
  status: 'none',
  message: '',
};

export const statusAppState = createSlice({
  name: 'statusApp',
  initialState,
  reducers: {},
  extraReducers: {
    [setStatus.type]: (state, action: PayloadAction<StatusAppState>) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
    },
  },
});

export default statusAppState.reducer;
