import { Button } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { setModal } from 'store/scripts/actions';

import { ButtonCreateScriptProps } from './types';

const ButtonCreateScript = ({ text, className }: ButtonCreateScriptProps) => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(setModal({ type: 'create' }));
  };

  return (
    <Button onClick={onClick} className={className}>
      {text}
    </Button>
  );
};

export default ButtonCreateScript;
