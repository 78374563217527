import './style.scss';

import { useCallback } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import { ControlsInTableProps } from './types';

const ControlsInTable = (props: ControlsInTableProps) => {
  const { children, className, editable, onRowEdit, isEdit } = props;

  const onEditClick = useCallback(() => onRowEdit?.(), [onRowEdit]);

  return (
    <Dropdown className='controlsTable'>
      {editable && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span className='controlsTable__edit' onClick={onEditClick}>
          <i className={`bx bxs-${isEdit ? 'save' : 'pencil'}`}></i>
        </span>
      )}
      <Dropdown.Toggle
        className={`${className || 'controlsTable controlsTable__toggle '}`}
        as='span'
      >
        <i className='bx bx-dots-horizontal-rounded'></i>
      </Dropdown.Toggle>
      <Dropdown.Menu className='controlsTable__menu'>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default ControlsInTable;
