import './style.scss';

import { PlusSvg } from 'components/Icons';
import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { generateUid } from 'utils/app_helper';

import { WebhookItemType, WebhooksListProps } from './types';
import WebhookItem from './WebhookItem';

const webhookDataKey = 'data.webhooks';

const WebhooksList: FC<WebhooksListProps> = ({ items, onChangeState }) => {
  const changeDataWebhooks = (newValues: WebhookItemType[]) => {
    onChangeState({ [webhookDataKey]: newValues });
  };

  const onAddWebhook = () => {
    const newItem: WebhookItemType = {
      id: generateUid(),
      method: 'POST',
      url: '',
    };
    const newItems = items ? [...items, newItem] : [newItem];
    changeDataWebhooks(newItems);
  };

  const onEditWebhook = (
    itemId: string,
    newItemValues: Partial<WebhookItemType>
  ) => {
    if (!items) return;
    const newItems = items.map((item) => {
      if (item.id === itemId) return { ...item, ...newItemValues };
      return item;
    });
    changeDataWebhooks(newItems);
  };

  const onDeleteWebhook = (itemId: string) => {
    if (!items) return;
    const newItems = items.filter((item) => item.id !== itemId);
    changeDataWebhooks(newItems);
  };

  return (
    <div className='panelNode__webhook_list_wrapper'>
      <div className='node__webhook_head'>
        <div>Добавить веб хук:</div>
        <Button
          className='node_webhook_add_btn'
          onClick={onAddWebhook}
          disabled={!!(items && items.length >= 15)}
        >
          <PlusSvg />
        </Button>
      </div>
      <div className='panelNode__webhook_list'>
        {items?.map((item) => (
          <WebhookItem
            key={item.id}
            item={item}
            onDelete={onDeleteWebhook}
            onEdit={onEditWebhook}
          />
        ))}
      </div>
    </div>
  );
};
export default WebhooksList;
