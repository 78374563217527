import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchScript } from 'store/scripts/actions';

import NoScripts from '../NoScripts';
import PanelSearchScripts from '../PanelSearchScripts';
import TableScripts from '../TableScripts';

const ContentPage = () => {
  const { scripts, isLoadingScripts, searchBy } = useAppSelector(
    (state) => state.scripts
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchScript());
  }, [dispatch]);

  if (isLoadingScripts) return <></>;

  // проверка чтобы понимать скриптов нет по фильтру или пользователь ещё не создавал их
  if (!scripts.length && !searchBy) return <NoScripts />;

  return (
    <>
      <PanelSearchScripts />
      <TableScripts />
    </>
  );
};

export default ContentPage;
