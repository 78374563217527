import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TABLE_PAGINATION_DEF } from 'constants/app_constants';
import { PaginationConfig } from 'models/response';

import {
  createScript,
  deleteScript,
  fetchScript,
  setModal,
  setSearchConfig,
  setTableConfig,
} from './actions';
import { ScriptConfig, ScriptsState } from './types';

const initialState: ScriptsState = {
  isLoadingScripts: true,
  modal: { type: 'none' },
  scripts: [],

  tableConfig: {
    ...TABLE_PAGINATION_DEF,
    sortBy: {
      name: 'asc',
    },
  },
  rowsEditing: {},
};

export const scriptsState = createSlice({
  name: 'scripts',
  initialState,
  reducers: {
    setRowEdit(state, action: PayloadAction<ScriptConfig>) {
      const editedScript = state.rowsEditing[action.payload.id_script];
      const isEdit = !!editedScript;
      state.rowsEditing[action.payload.id_script] = isEdit
        ? false
        : action.payload;
      if (isEdit && typeof editedScript === 'object') {
        state.scripts = state.scripts.map((script) =>
          script.id_script === action.payload.id_script
            ? { ...script, ...editedScript }
            : script
        );
      }
    },
    updateRowEdit(
      state,
      action: PayloadAction<{ id: number; changed: Partial<ScriptConfig> }>
    ) {
      state.rowsEditing[action.payload.id] = {
        ...(state.rowsEditing[action.payload.id] as ScriptConfig),
        ...action.payload.changed,
      };
    },
  },
  extraReducers: {
    [fetchScript.pending.type]: () => {
      // state.isLoadingScripts = true;
    },
    [fetchScript.fulfilled.type]: (
      state,
      action: PayloadAction<PaginationConfig<ScriptConfig[]>>
    ) => {
      state.scripts = action.payload.data;
      state.isLoadingScripts = false;
      state.tableConfig.totalSize = action.payload.pagination.total;

      // для появления модалки "Сейчас у вас нет ни одного скрипта,"
      if (
        state.searchBy &&
        !Object.keys(state.searchBy).length &&
        action.payload.pagination.total === 0
      ) {
        state.searchBy = undefined;
      }
    },
    [fetchScript.rejected.type]: (state, _: PayloadAction<ScriptConfig[]>) => {
      state.scripts = [];
      state.searchBy = undefined;
      state.isLoadingScripts = false;
      state.tableConfig = {
        ...TABLE_PAGINATION_DEF,
        sortBy: {
          name: 'asc',
        },
      };
    },
    [createScript.fulfilled.type]: (state, _: PayloadAction<ScriptConfig>) => {
      state.modal = { type: 'none' };
    },

    [deleteScript.fulfilled.type]: (
      state,
      _: PayloadAction<{ scriptId: number }>
    ) => {
      state.modal = { type: 'none' };
    },

    //   ------------------ modal -----------------

    [setModal.type]: (state, action: PayloadAction<ModalConfig>) => {
      state.modal = action.payload;
    },

    //   ------------------ pagination -----------------

    [setTableConfig.type]: (state, action: PayloadAction<TableConfig<any>>) => {
      state.tableConfig = {
        ...state.tableConfig,
        ...action.payload,
      };
    },
    //   ------------------ search -----------------

    [setSearchConfig.type]: (
      state,
      action: PayloadAction<{ [key: string]: string }>
    ) => {
      state.searchBy = {
        ...state.searchBy,
        ...action.payload,
      };

      // чтобы не отправлять запросы с пустым значением {name:''}
      for (const [key, value] of Object.entries(state.searchBy)) {
        if (value === '' && key in state.searchBy) {
          // @ts-ignore
          delete state.searchBy[key];
        }
      }
    },
  },
});

export const { setRowEdit, updateRowEdit } = scriptsState.actions;

export default scriptsState.reducer;
