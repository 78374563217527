import './style.scss';

import logoLightSvg from 'assets/images/logo.svg';
import Modal from 'react-bootstrap/Modal';

import { ModalHeaderProps } from './types';

const ModalHeader = (props: ModalHeaderProps) => {
  const { title, subtitle } = props;

  return (
    <Modal.Header className='modal_header'>
      <div>
        <h3 className='modal_header__text modal_header__text_level_1'>
          {title}
        </h3>
        <h6 className='modal_header__text modal_header__text_level_2'>
          {subtitle}
        </h6>
      </div>
      <img src={logoLightSvg} alt='' className='modal_header__logo' />
    </Modal.Header>
  );
};

export default ModalHeader;
