import './style.scss';

import Container from 'react-bootstrap/Container';

import { ContentProps } from './types';

const Content = ({ children }: ContentProps) => {
  return (
    <div className='content content__block_main'>
      <div className='content content__block_page'>
        <Container fluid> {children}</Container>
      </div>
    </div>
  );
};

export default Content;
