import {
  DEF_EDGE_DATA,
  DEF_NODE_DATA,
  TYPE_EDGE,
  TYPE_NODE,
  TYPES_ARROW,
} from 'constants/graph';
import { useUndoableContext } from 'contexts/undoable';
import {
  addEdge,
  applyNodeChanges,
  Edge,
  Node,
  NodeProps,
} from 'react-flow-renderer';

import { getRandomArbitrary, uuid } from '../utils';

export function useButtonAddNode(id: NodeProps['id']) {
  const { getNode, setElements } = useUndoableContext();

  const onClick = () => {
    const parentNode = getNode(id);

    if (!parentNode) {
      return;
    }

    const childNodeId = uuid();
    const { x: xPos, y: yPos } = parentNode.position;
    const shiftChildNode = getRandomArbitrary(-200, 200);

    const childNode: Node<NodeData> = {
      id: childNodeId,
      type: TYPE_NODE,
      data: DEF_NODE_DATA,
      position: { x: xPos + shiftChildNode, y: yPos + 200 },
    };
    // @ts-ignore
    const childEdge: Edge<EdgeData> = {
      id: `${parentNode.id}=>${childNodeId}`,
      source: parentNode.id,
      target: childNodeId,
      type: TYPE_EDGE,
      ...TYPES_ARROW.neutral,
      data: DEF_EDGE_DATA,
    };

    setElements((elements) => ({
      ...elements,
      nodes: elements?.nodes
        ? applyNodeChanges(
            [{ item: childNode, type: 'add' }],
            elements.nodes.map((node) => ({
              ...node,
              selected: node.id === id,
            }))
          )
        : elements.nodes,
      edges: elements.edges
        ? addEdge(
            childEdge,
            elements.edges.map((edge) => ({ ...edge, selected: false }))
          )
        : elements.edges,
    }));
  };

  return onClick;
}

export default useButtonAddNode;
