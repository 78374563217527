import { Avatar } from 'components';
import { UserConfig } from 'store/users/types';

import Controls from '../Controls';

type sortFunc = (column: string, order: string) => void;
interface paramsColumns {
  sortFunc: sortFunc;
  size: number;
}

export const getTableUsersColumns = (params: paramsColumns) => [
  {
    dataField: 'id_user',
    text: '',
    headerClasses: 'col-1',
    formatter: (_: string, row: UserConfig) => {
      return <Avatar value={row.fio} />;
    },
  },

  {
    dataField: 'fio',
    text: 'ФИО',
    onSort: params.sortFunc,
    sort: true,
  },
  {
    dataField: 'role',
    text: 'Роль',
    onSort: params.sortFunc,
    sort: true,
  },
  {
    dataField: 'created_at',
    text: 'Дата создания',
    onSort: params.sortFunc,
    sort: true,
  },
  {
    dataField: 'login',
    text: 'Логин',
    onSort: params.sortFunc,
    sort: true,
  },
  {
    dataField: 'controls',
    headerClasses: 'col-3',
    text: '',
    sort: false,
    formatter: (_: string, row: UserConfig) => (
      <Controls userId={row.id_user} />
    ),
  },
];
