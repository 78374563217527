import PasswordInput from 'components/PasswordInput/PasswordInput';
import { ENDPOINTS } from 'constants/api_endpoints';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { selctedScriptIdSelector } from 'store/editor/selectors';
import { useAppSelector } from 'store/hooks';
import axiosApi from 'utils/api_helper';

import { useSendSuiteCrmAuth } from './useSendSuiteCrmAuth';

export interface FormValues {
  url: string;
  login: string;
  password: string;
  client_id: string;
  client_secret: string;
}

const initialValues: FormValues = {
  login: '',
  password: '',
  url: '',
  client_id: '',
  client_secret: '',
};

interface GetAuthResponse {
  data: {
    client_id: string;
    client_secret: string;
    pwd: string;
    url: string;
    username: string;
  };
}

const SuiteCrmIntegration = () => {
  const selectedScriptId = useAppSelector(selctedScriptIdSelector);
  const [initLoading, setInitLoading] = useState(false);
  const [sendAuth, submitLoading] = useSendSuiteCrmAuth();
  const { handleChange, handleSubmit, values, setValues } =
    useFormik<FormValues>({
      initialValues,
      async onSubmit(data) {
        await sendAuth(data);
      },
    });

  useEffect(() => {
    setInitLoading(true);
    axiosApi
      .post<GetAuthResponse>(`${ENDPOINTS.CRM_GET_AUTH}`, {
        key_crm: 'suite',
        id_script: selectedScriptId,
      })
      .then((response) => {
        const { pwd, username, ...data } = response.data.data;
        const formatedData: FormValues = {
          ...data,
          password: pwd,
          login: username,
        };
        setValues(formatedData);
      })
      .finally(() => {
        setInitLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='suitecrm__form_title'>
        Адрес и метод авторизации для Suite CRM
      </div>
      <Form onSubmit={handleSubmit}>
        <fieldset disabled={initLoading}>
          <div className='suitecrm__form_inner'>
            <div className='suitecrm__form_inner_row'>
              <Form.Group>
                <Form.Label>IP-адрес:</Form.Label>
                <Form.Control
                  name='url'
                  value={values.url}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Логин:</Form.Label>
                <Form.Control
                  name='login'
                  value={values.login}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Пароль:</Form.Label>
                <PasswordInput
                  name='password'
                  type='password'
                  value={values.password}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className='suitecrm__form_inner_row'>
              <Form.Group>
                <Form.Label>Client Secret:</Form.Label>
                <PasswordInput
                  name='client_secret'
                  value={values.client_secret}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Client ID:</Form.Label>
                <Form.Control
                  name='client_id'
                  value={values.client_id}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
        </fieldset>
        <div className='mt-2 text-end'>
          <Button type='submit' disabled={submitLoading}>
            Сохранить
          </Button>
        </div>
      </Form>
    </>
  );
};
export default SuiteCrmIntegration;
