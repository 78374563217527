import './Tooltip.scss';

import { PRIVATE_ROUTES } from 'navigation';
import { RouteType } from 'navigation/types';
import { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { getUserPagesSelector } from 'store/user/selectors';

import NavItem from './NavItem';

const Toolbar: FC<PropsWithChildren> = ({ children }) => {
  const { pathname: path } = useLocation();
  const pages = useAppSelector(getUserPagesSelector);

  const isScriptSettingsNavButtonShown =
    PRIVATE_ROUTES.EDITOR.path === path ||
    PRIVATE_ROUTES.SCRIPT_SETTINGS.path === path;
  const navItems: [RouteType, boolean?][] = [
    [PRIVATE_ROUTES.SCRIPTS],
    [PRIVATE_ROUTES.USERS],
    [PRIVATE_ROUTES.SCRIPT_SETTINGS, isScriptSettingsNavButtonShown],
  ];

  const activeItem = navItems.reduce<null | RouteType>(
    (prev, [item]) =>
      path.includes(item.path) || path.includes(item.path) ? item : prev,
    null
  );
  const activeMenu = activeItem?.id ?? null;

  const filterNavItemsByRights = navItems.filter(([item]) =>
    pages.some((page) => item.parent === page || item.id === page)
  );

  return (
    <div className='toolbar'>
      <div className='toolbar__logo'> {children}</div>

      <div className='toolbar__content'>
        {filterNavItemsByRights.map(([item, isShow]) => {
          if (isShow === undefined || isShow)
            return (
              <NavItem key={item.id} activeMenu={activeMenu} item={item} />
            );
          return null;
        })}
      </div>
    </div>
  );
};

export default Toolbar;
