import { ConnectionLineProps } from './types';

const ConnectionLine = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
}: ConnectionLineProps) => {
  return (
    <g>
      <path
        fill='none'
        stroke='#b1b1b7'
        strokeWidth={3}
        d={`M ${sourceX},${sourceY}L ${targetX},${targetY}`}
      />
      <circle
        cx={targetX}
        cy={targetY}
        fill='#fff'
        r={2}
        stroke='#b1b1b7'
        strokeWidth={2}
      />
    </g>
  );
};

export default ConnectionLine;
