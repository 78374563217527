import { EdgeTypes, NodeTypes } from 'react-flow-renderer';

import { Edge, Node } from './components';

export const nodeTypes: NodeTypes = {
  node: Node,
};
export const edgeTypes: EdgeTypes = {
  edge: Edge,
};
