// import { PUBLIC_ROUTES } from 'navigation';

export const ITEMS_PROFILE_MENU = [
  {
    path: '/logout',
    text: 'Выход',
    icon: 'bx-power-off',
    isDetach: false,
    appearance: 'danger',
  },
];
