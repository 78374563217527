import './style.scss';

import { TableWithPagination } from 'modules';
import { useAppSelector } from 'store/hooks';
import { setPagination } from 'store/scripts/actions';

import { getColumnsScriptsTable } from './columns';

const TableScripts = () => {
  const { scripts, tableConfig } = useAppSelector((state) => state.scripts);

  return (
    <TableWithPagination
      keyField='id_script'
      data={scripts as unknown as Record<string, unknown>[]}
      getColumns={getColumnsScriptsTable}
      tableConfig={tableConfig}
      dispatchAction={setPagination}
    />
  );
};

export default TableScripts;
