import './styles.scss';

import { Breadcrumbs } from 'components';
import { PRIVATE_ROUTES } from 'navigation';
import { useEffect } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { fetchEditorScript, updateEditorScript } from 'store/editor/actions';
import {
  editorStateSelector,
  selectedScriptSelector,
} from 'store/editor/selectors';
import { InitialScript } from 'store/editor/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchScript } from 'store/scripts/actions';

import CssInjection from './components/CssInjection';
import SuiteCrmIntegration from './components/SuiteCrmIntegration/SuiteCrmIntegration';

const ScriptSettings = () => {
  const { initialScript, selectedEditorScriptId } =
    useAppSelector(editorStateSelector);
  const dispatch = useAppDispatch();
  const scripts = useAppSelector((state) => state.scripts.scripts);
  const selectedScript = useAppSelector(selectedScriptSelector);

  const onChangeCssInjection = (newCssValue: string) => {
    if (initialScript) {
      const newScriptData: InitialScript = {
        ...initialScript,
        css: newCssValue,
      };
      dispatch(updateEditorScript(newScriptData));
    }
  };

  useEffect(() => {
    if (selectedEditorScriptId && !initialScript) {
      dispatch(fetchEditorScript());
    }
  }, [initialScript, selectedEditorScriptId, dispatch]);

  useEffect(() => {
    if (selectedEditorScriptId && scripts.length === 0) {
      dispatch(fetchScript());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!selectedEditorScriptId)
    return <Navigate to={PRIVATE_ROUTES.SCRIPTS.path} />;

  return (
    <>
      <Breadcrumbs
        title='Настройки интеграции с внешними системами'
        breadcrumbItems={[
          { title: 'Cкрипты', to: PRIVATE_ROUTES.SCRIPTS.path },
          {
            title: `Скрипт «${selectedScript?.name ?? ''}»`,
            to: PRIVATE_ROUTES.EDITOR.path,
          },
          'Настройки интеграции с внешними системами',
        ]}
      />
      <Card className='card__fullscreen script__settings_card'>
        <Card.Body className='card__body_full_size'>
          <Tabs className='script__settings_tabs'>
            <Tab title='Интеграция с SuiteCRM' eventKey='suitecrm'>
              <SuiteCrmIntegration />
            </Tab>
            <Tab title='CSS для встройки формы' eventKey='css_form'>
              <CssInjection
                initCss={initialScript?.css}
                onChangeCssInjection={onChangeCssInjection}
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};
export default ScriptSettings;
