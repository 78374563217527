/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './styles.scss';

import classNames from 'classnames';
import { FC, useState } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

const PasswordInput: FC<FormControlProps & { name: string }> = ({
  className,
  ...props
}) => {
  const [isText, setIsText] = useState(false);
  const inputType = isText ? 'text' : 'password';

  const onChangeInputType = () => setIsText((prev) => !prev);
  return (
    <div className='password__input_wrapper'>
      <Form.Control
        {...props}
        type={inputType}
        className={classNames('password__input', className)}
      />
      <i className='bx bx-show' onClick={onChangeInputType} />
    </div>
  );
};
export default PasswordInput;
