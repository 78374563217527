import './style.scss';

import { FormInput } from 'components';
import { useUndoableContext } from 'contexts/undoable';
import { useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Edge } from 'reactflow';

import Editor from '../Editor';
import { InputSearchNode, StatusOptions } from './components';
import WebhooksList from './components/WebhooksList';
import { EdgeFieldsProps } from './types';

function EdgeFields(props: EdgeFieldsProps) {
  const { edge, typeTargetField, typeSourceField, onChangeState } = props;
  const { getEdge } = useUndoableContext();

  const edgeData = useMemo(
    () => getEdge(edge.id)?.data ?? undefined,
    [getEdge, edge.id]
  );

  const updateValue = useCallback(
    (prop: keyof Edge['data'], valueAccessor: 'checked' | 'value') =>
      (input: HTMLInputElement) => {
        if (
          edgeData?.[prop] &&
          input &&
          input[valueAccessor] !== edgeData[prop]
        ) {
          // @ts-ignore
          input[valueAccessor] = edgeData[prop];
        }
      },
    [edgeData]
  );

  const updateStatusOptions = useCallback(
    (value: string, setValue: (arg0: string) => void) => {
      if (edgeData && value !== edgeData.type) {
        setValue(edgeData.type);
      }
    },
    [edgeData]
  );

  return (
    <div className='edgeFields'>
      <div className='panelNode__item'>
        <label htmlFor='label'>Наименование ответа:</label>
        <FormInput
          id='label'
          name='data.label'
          className='form-control'
          key={`edge_${edge.id}_label`}
          onChangeState={onChangeState}
          defaultValue={edge.data.label}
          updateValue={updateValue('label', 'value')}
        />
      </div>
      <div className='panelNode__item'>
        <label htmlFor='description'>Выбрать шаг:</label>
        <InputSearchNode
          currentNodeId={edge[typeSourceField]}
          nodeId={edge[typeTargetField]}
          onChangeState={onChangeState}
          name={typeTargetField}
        />
      </div>
      <div className='panelNode__item'>
        <label htmlFor='description'>Описание ответа:</label>
        <Editor
          onChangeState={onChangeState}
          name='data.description'
          defaultValue={edge.data?.description || ''}
        />
      </div>
      <div className='panelNode__item'>
        <StatusOptions
          onChangeState={onChangeState}
          name='data.type'
          defaultValue={edge.data?.type || ''}
          updateValue={updateStatusOptions}
        />
      </div>
      <WebhooksList onChangeState={onChangeState} items={edge?.data.webhooks} />

      <Button type='submit' disabled={edge[typeTargetField] === '-1'}>
        Сохранить
      </Button>
    </div>
  );
}

export default EdgeFields;
