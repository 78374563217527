import { useFormik } from 'formik';
import { useCreateStep } from 'hooks';
import { Edge } from 'react-flow-renderer';

import EdgeFields from '../EdgeFields';
import { WebhookItemType } from '../EdgeFields/components/WebhooksList/types';
import { BodyProps } from './types';

function FormCreateEdge(props: BodyProps) {
  const { typeTargetField, nodeParent, callBack } = props;
  const { onCreateStep } = useCreateStep();
  const typeSourceField = typeTargetField === 'source' ? 'target' : 'source';
  const initialValues = {
    id: '-1',
    label: 'Новый шаг',
    data: { type: 'neutral' },
    [typeTargetField]: '-1',
    [typeSourceField]: nodeParent.id,
  } as unknown as Edge;
  const formik = useFormik({
    initialValues,
    onSubmit: (data) => {
      onCreateStep({ data, typeTargetField, nodeParent });
      callBack();
    },
  });

  const onChangeState = (field: {
    [key: string]: string | boolean | WebhookItemType[];
  }) => {
    const [key, value] = Object.entries(field)[0];
    formik.handleChange({
      target: {
        name: key,
        value,
      },
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <EdgeFields
        edge={formik.values}
        typeTargetField={typeTargetField}
        typeSourceField={typeSourceField}
        onChangeState={onChangeState}
      />
    </form>
  );
}

export default FormCreateEdge;
