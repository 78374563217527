import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';

import FormEditEdge from '../FormEditEdge';
import { PanelEdgesProps } from './types';

function PanelEdges({
  edges,
  typeTargetField,
  selectedEdgeId,
}: PanelEdgesProps) {
  const [activeKeys, setActiveKeys] = useState<string[]>([
    selectedEdgeId || '-1',
  ]);
  if (!edges.length) return null;

  const callBack = (edgeId: string) => {
    return () => {
      setActiveKeys(activeKeys.filter((key) => key !== edgeId));
    };
  };

  const onSelect = (eventKey: AccordionEventKey) => {
    if (Array.isArray(eventKey)) {
      setActiveKeys([...eventKey]);
    }
  };

  return (
    <>
      <Accordion alwaysOpen activeKey={activeKeys} onSelect={onSelect}>
        {edges.map((edge) => (
          <FormEditEdge
            key={edge.id}
            edge={edge}
            typeTargetField={typeTargetField}
            callBack={callBack(edge.id)}
          />
        ))}
      </Accordion>
    </>
  );
}

export default PanelEdges;
