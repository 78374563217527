import './style.scss';

import { CloseButton, ModalHeader } from 'components';
import { Modal } from 'react-bootstrap';
import { useAppSelector } from 'store/hooks';

import FormCreateUser from './FormCreateUser';
import useCloseModal from './hooks/useCloseModal';

const ModalCreateUser = () => {
  const { modal } = useAppSelector((state) => state.users);
  const closeModal = useCloseModal();
  const onCloseModal = () => {
    closeModal();
  };

  return (
    <Modal
      show={modal.type === 'create' || modal.type === 'edit'}
      className='modalAuth'
      centered
      onHide={onCloseModal}
    >
      <CloseButton onClose={onCloseModal} />
      <ModalHeader title='Добавить пользователя' subtitle='' />
      <Modal.Body>
        <FormCreateUser />
      </Modal.Body>
    </Modal>
  );
};

export default ModalCreateUser;
