import { useDebounce } from 'hooks';
import { useAppDispatch } from 'store/hooks';
import { setSearch } from 'store/scripts/actions';

import { PanelSearchSelectProps } from './types';

function PanelSearchSelect(props: PanelSearchSelectProps) {
  const { name, label, className } = props;

  const dispatch = useAppDispatch();

  const onChange = useDebounce(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;
      dispatch(setSearch({ [name]: value }));
    },
    600
  );

  return (
    <div className={className}>
      <label htmlFor={name}>{label}:</label>
      <input className='form-control' onChange={onChange}></input>
    </div>
  );
}

export default PanelSearchSelect;
