import { Layout } from 'modules';

import { RouteElementProps } from './types';

const RouteElement = ({
  component: Component,
  layout = false,
}: RouteElementProps) => {
  if (!layout) {
    return <Component />;
  }

  return (
    <Layout>
      <Component />
    </Layout>
  );
};
export default RouteElement;
