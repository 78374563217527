import * as yup from 'yup';

export const validationsSchema = yup.object().shape({
  fio: yup
    .string()
    .typeError('Должно быть строкой')
    .required('Обязательное поле'),
  id_role: yup.string().required('Необходимо выбрать роль'),
  login: yup.string().required('Обязательное поле'),

  password: yup.string().when('id_user', {
    is: (val: number) => val === 0,
    then: yup
      .string()
      .min(8, 'Минимальная длина пароль 8 символов')
      .required('Обязательное поле'),
    otherwise: yup.string().when('password', (password, schema) => {
      if (password) {
        return password.length > 0
          ? schema.min(8, 'Минимальная длина пароль 8 символов')
          : schema;
      }
      return schema;
    }),
  }),
});
