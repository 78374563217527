import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './style.scss';

import BootstrapTable, {
  BootstrapTableProps,
} from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import { getPaginationOptions } from './constants';
import { TableProps } from './types';

const Table = (props: TableProps) => {
  const {
    data,
    columns,
    keyField = 'id',
    paginationOptions,
    className,
  } = props;

  if (!data) return null;

  const paginationConfig = getPaginationOptions(paginationOptions);

  return (
    <ToolkitProvider
      keyField={keyField}
      columns={columns}
      data={data}
      className={`${className} table`}
    >
      {(toolkitProps: { baseProps: Omit<BootstrapTableProps, 'keyField'> }) => (
        <BootstrapTable
          keyField='id'
          remote // <-  very important flag when using pagination
          bordered={false}
          striped={false}
          classes='react-bootstrap-table table align-middle table-nowrap'
          headerWrapperClasses='thead-light'
          pagination={paginationFactory(paginationConfig)}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onTableChange={() => {}}
          {...toolkitProps.baseProps}
        />
      )}
    </ToolkitProvider>
  );
};

export default Table;
