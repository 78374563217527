import { AxiosResponse } from 'axios';
import { ENDPOINTS } from 'constants/api_endpoints';
import { IResponse, PaginationConfig } from 'models/response';
import axiosApi from 'utils/api_helper';

import { UserConfig } from './types';

export default class UsersService {
  static async fetchUsers(params: { query: Record<string, unknown> }) {
    const response: AxiosResponse<IResponse<PaginationConfig<UserConfig[]>>> =
      await axiosApi.get(ENDPOINTS.USERS_FETCH, { params: params.query });

    return response;
  }

  static async createUser(userData: UserConfig) {
    const response: AxiosResponse<IResponse<any>> = await axiosApi.post(
      ENDPOINTS.USERS_FETCH,
      userData
    );

    return response;
  }

  static async updateUser(userData: UserConfig) {
    const response: AxiosResponse<IResponse<any>> = await axiosApi.put(
      `${ENDPOINTS.USERS_FETCH}/${userData.id_user}`,
      userData
    );

    return response;
  }

  static async deleteUser(userId: number) {
    const response: AxiosResponse<IResponse<any>> = await axiosApi.delete(
      ENDPOINTS.USERS_FETCH + '/' + userId
    );

    return response;
  }
}
