import { TYPE_NODE as typeNode } from 'constants/graph';
import { Edge, Node } from 'react-flow-renderer';

import { InitialScript } from './types';

const initialNodes: Node<NodeData>[] = [
  {
    id: 'START',
    type: typeNode,
    data: {
      label: 'Первый шаг',
      description: 'Описание первого шага ',
      purpose: false,
    },
    position: { x: 400, y: 20 },
  },
];
const initialEdges: Edge<EdgeData>[] = [];

export const initialScript: InitialScript = {
  nodes: initialNodes,
  edges: initialEdges,
};
