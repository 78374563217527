import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const rolesState = (state: RootState) => state.roles;

export const getSelectOptions = createSelector(rolesState, (state) => {
  const options = state.roles.map((role) => ({
    value: role.id_role,
    label: role.role,
  }));

  return options;
});

export const getQueryTable = (state: any) => {
  const tableConfig = state.roles.tableConfig;
  const [keyFieldSort, typeFieldSort] = Object.entries(tableConfig.sortBy)[0];

  const queryObj = {
    count: tableConfig.size,
    page: tableConfig.page,
    sort_by: keyFieldSort,
    sort_type: typeFieldSort,
  };

  return queryObj;
};
