import './style.scss';

import { TrashSvg } from 'components/Icons';
import { ChangeEvent, FC } from 'react';
import { Form } from 'react-bootstrap';

import { WebhookItemType } from '../types';

interface Props {
  item: WebhookItemType;
  onEdit: (itemId: string, newItemValues: Partial<WebhookItemType>) => void;
  onDelete: (itemId: string) => void;
}

const WebhookItem: FC<Props> = ({ item, onDelete, onEdit }) => {
  const onUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    onEdit(item.id, { url: target.value });
  };
  return (
    <div className='panelNode__webhook_item_wrapper'>
      <Form.Control value={item.url} onChange={onUrlChange} />
      <TrashSvg onClick={() => onDelete(item.id)} />
    </div>
  );
};
export default WebhookItem;
