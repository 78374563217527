import { FormInput } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateRowEdit } from 'store/scripts/slice';

import { EditableCellProps } from './types';

const EditableCell = (props: EditableCellProps) => {
  const { initValue, id, name, isEditSelector } = props;
  const dispatch = useAppDispatch();
  const isEdit = useAppSelector(isEditSelector(props.id));

  const fieldName = id + name;

  return isEdit ? (
    <FormInput
      id={fieldName}
      name={name}
      key={fieldName}
      className='form-edit'
      onChangeState={(changed: Record<string, string>) => {
        dispatch(updateRowEdit({ id, changed }));
      }}
      defaultValue={initValue}
    />
  ) : (
    <>{initValue}</>
  );
};

export default EditableCell;
