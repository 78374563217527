import { useDebounce } from 'hooks';
import { ElementType, useLayoutEffect, useRef } from 'react';

import { InputProps } from './types';
const defaultElement = 'input';

function FormInput<E extends ElementType = typeof defaultElement>({
  onChangeState,
  name,
  as,
  className,
  updateValue,
  ...otherProps
}: InputProps<E>) {
  const TagName = as || defaultElement;

  const refInput = useRef<HTMLInputElement>(null);

  const onChange = useDebounce(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;
      onChangeState({ [name]: value });
    },
    600
  );

  useLayoutEffect(() => {
    if (updateValue && refInput.current) {
      updateValue(refInput.current);
    }
  }, [updateValue]);

  return (
    <TagName
      ref={refInput}
      className={className}
      onChange={onChange}
      {...otherProps}
    />
  );
}

export default FormInput;
