import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { setStatus } from '../statusApp/actions';
import { getQueryTable } from './selectors';
import UsersService from './service';
import { UserConfig } from './types';

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (_: void, thunkAPI) => {
    try {
      const queryTable = getQueryTable(thunkAPI.getState());
      const response = await UsersService.fetchUsers({ query: queryTable });

      return response.data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          const errorMessage = error.response.data.message;

          thunkAPI.dispatch(
            setStatus({ status: 'danger', message: errorMessage })
          );
          return thunkAPI.rejectWithValue(errorMessage);
        }
        thunkAPI.dispatch(
          setStatus({ status: 'danger', message: error.message })
        );
        return thunkAPI.rejectWithValue(error.message);
      }
      thunkAPI.dispatch(
        setStatus({ status: 'warning', message: 'Неизвестная проблема' })
      );
      return thunkAPI.rejectWithValue('Неизвестная проблема');
    }
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (user: { userId: number }, thunkAPI) => {
    try {
      await UsersService.deleteUser(user.userId);
      thunkAPI.dispatch(fetchUsers());
      return user;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          thunkAPI.dispatch(
            setStatus({
              status: 'danger',
              message: error.response.data.message,
            })
          );
          return thunkAPI.rejectWithValue(error.response.data.message);
        }

        thunkAPI.dispatch(
          setStatus({
            status: 'danger',
            message: error.message,
          })
        );
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue('Неизвестная проблема');
    }
  }
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (userData: UserConfig, thunkAPI) => {
    try {
      if (userData.id_user === 0) {
        await UsersService.createUser(userData);
      } else {
        await UsersService.updateUser(userData);
      }

      thunkAPI.dispatch(fetchUsers());
      return userData;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          const errorMessage = error.response.data.message;

          thunkAPI.dispatch(
            setStatus({ status: 'danger', message: errorMessage })
          );

          return;
        }
      }
      return thunkAPI.rejectWithValue('Неизвестная проблема');
    }
  }
);

export const setTableConfig = createAction<any>('users/setTableConfig');

export const setPagination = createAsyncThunk(
  'users/setPagination',
  async (tableConfigParameter: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(setTableConfig(tableConfigParameter));
      thunkAPI.dispatch(fetchUsers());

      return tableConfigParameter;
    } catch (error) {}
  }
);

export const setModal = createAction<ModalConfig>('users/modal');
