/* eslint-disable @typescript-eslint/no-explicit-any */

export const useDebounce = (
  func: (...params: any) => any,
  milliseconds?: number
) => {
  const time = milliseconds || 400;
  let timer: NodeJS.Timeout;

  return (event: any) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(func, time, event);
  };
};
