export const SERVER_URL =
  process.env.REACT_APP_SERVER_URL || 'http://localhost:8000/api';

export const ENDPOINTS = {
  AUTH_LOGIN: 'auth/login', // login
  AUTH_REFRESH: 'auth/refresh', // refresh token
  AUTH_LOGOUT: 'auth/logout', // logout
  USERS_FETCH_CURRENT: 'users/current',
  USERS_FETCH: 'users',
  ROLES_FETCH: 'roles',
  SCRIPT: 'script',
  SCRIPT_UPDATE: 'script/name_goal',
  EDITOR_FETCH: 'script',
  EDITORS_GOALS_FETCH: 'script/goals',
  EDITOR_COPY: 'script/copy',

  FORMS: 'forms',
  CRM: 'crm',
  GET_CRM_FIELDS: 'crm/get_all_fields',
  CRM_CREATE_AUTH: 'crm/create_auth',
  CRM_GET_AUTH: 'crm/get_auth',
};
