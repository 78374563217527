import './style.scss';

import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { login } from 'store/auth/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const FormAuth = () => {
  const { isLoading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const dataUser = {
      // @ts-ignore
      login: event.target[0].value,
      // @ts-ignore
      password: event.target[1].value,
    };
    dispatch(login(dataUser));
  };

  return (
    <>
      <Form className='formAuth' onSubmit={onSubmit}>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Логин</Form.Label>
          <Form.Control type='text' placeholder='Логин' required />
          <Form.Control.Feedback type='invalid'>
            Необходимо ввеcти логин
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicPassword'>
          <Form.Label>Пароль</Form.Label>
          <Form.Control type='password' placeholder='Пароль' required />
        </Form.Group>
        <br />
        <Button variant='primary' type='submit' className='formAuth__button'>
          Войти
          {isLoading && (
            <Spinner animation='border' size='sm' className='ms-3' />
          )}
        </Button>
      </Form>
    </>
  );
};

export default FormAuth;
