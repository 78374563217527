import { Select } from 'components';
import { useField } from 'formik';
import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { getSelectOptions } from 'store/roles/selectors';

import { SelectRolesProps } from './types';

const SelectRoles = ({ fieldName }: SelectRolesProps) => {
  const [field, , helpers] = useField(fieldName);
  const roles = useAppSelector(getSelectOptions);

  const onChange = (option: Option) => {
    helpers.setValue(option.value);
  };

  const selectedValue = useMemo(
    () => roles.find((roleObj) => field.value === roleObj.value),
    [field.value, roles]
  );

  return (
    <Select
      id={fieldName}
      placeholder=''
      options={roles}
      onChange={onChange}
      defaultValue={selectedValue}
    />
  );
};

export default SelectRoles;
