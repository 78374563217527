import './Header.scss';

import {
  BrandBox,
  ProfileMenu,
  ProfileMenuItem,
  SwitcherFullScreen,
} from 'components';
// import { usePathsByRole } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { getUserData } from 'store/user/selectors';

import Tooltip from '../Toolbar/Toolbar';
import { ITEMS_PROFILE_MENU } from './HeaderConstatnst';

const Header = (): JSX.Element => {
  const { fio, role } = useAppSelector(getUserData);

  return (
    <header id='page-topbar'>
      <div className='navbar-header'>
        <Tooltip>
          <BrandBox />
        </Tooltip>

        <div className='d-flex align-items-center'>
          <ProfileMenu username={fio} position={role}>
            {ITEMS_PROFILE_MENU.map((item, i: number) => (
              <ProfileMenuItem key={`profile-item-${i}`} {...item} />
            ))}
          </ProfileMenu>
          <SwitcherFullScreen />
        </div>
      </div>
    </header>
  );
};

export default Header;
