import { Table } from 'components';
import { useAppDispatch } from 'store/hooks';

import { TableWithPaginationProps } from './types';

const TableWithPagination = (props: TableWithPaginationProps) => {
  const { data, keyField, tableConfig, getColumns, dispatchAction } = props;

  const dispatch = useAppDispatch();

  const onPageChange = (page: number) => {
    dispatch(dispatchAction({ page }));
  };

  const onSizeChange = (size: number) => {
    dispatch(dispatchAction({ size, page: 1 }));
  };

  const onSort = (column: string, order: string) => {
    dispatch(
      dispatchAction({
        sortBy: {
          [column]: order,
        },
      })
    );
  };

  return (
    <Table
      data={data}
      columns={getColumns({
        sortFunc: onSort,
        size: tableConfig.size,
      })}
      keyField={keyField}
      className='table'
      paginationOptions={{
        page: tableConfig.page,
        totalSize: tableConfig.totalSize,
        onPageChange: onPageChange,
        onSizePerPageChange: onSizeChange,
      }}
    />
  );
};

export default TableWithPagination;
