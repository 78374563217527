import './styles.scss';

import { ModalHeader } from 'components';
import Modal from 'react-bootstrap/Modal';

import { TEXT_IN_HEADER_AUTH } from './constants';
import { ModalAuthProps } from './types';

const ModalAuth = ({ children }: ModalAuthProps) => {
  return (
    <Modal show className='modalAuth' centered>
      <ModalHeader
        title={TEXT_IN_HEADER_AUTH.title}
        subtitle={TEXT_IN_HEADER_AUTH.subtitle}
      />
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default ModalAuth;
