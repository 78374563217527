export const nameApp = 'Po2juUQ/wAu992A0uWGqPWxyZ11mec8kEei0PzAMujI';
export const nameToken = 'token';
export const basename = process.env.REACT_APP_BASENAME || '';

export const REQUIRED_STATUS_FIELDS = {
  editor: ['selectedEditorScriptId'],
};

export const TABLE_PAGINATION_DEF = {
  page: 1,
  size: 10,
  totalSize: 10,
};
