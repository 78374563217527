import { TYPES_ARROW } from 'constants/graph';
import { useUndoableContext } from 'contexts/undoable';
import { Edge } from 'react-flow-renderer';

import { uuid } from '../utils';

export function useAddEdge() {
  const { setElements } = useUndoableContext();

  const onClick = (data: Edge<EdgeData>) => {
    const childEdgeId = uuid();
    const childEdge = {
      ...data,
      id: childEdgeId,
      ...TYPES_ARROW[data.data?.type || 'neutral'],
    };

    setElements((elements) => ({
      ...elements,
      edges: elements.edges
        ? elements.edges.concat([childEdge])
        : elements.edges,
    }));

    return childEdgeId;
  };

  return onClick;
}

export default useAddEdge;
