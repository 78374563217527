import './style.scss';

import { useUndoableContext } from 'contexts/undoable';
import { useRemoveEdge, useSelectingStep } from 'hooks';
import { MouseEvent } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { ItemHeaderProps, ItemProps } from './types';

function ItemHeader({ edge, typeTargetField }: ItemHeaderProps) {
  const onRemoveEdge = useRemoveEdge(edge.id);
  const { onSelectingNode } = useSelectingStep();
  const { getEdge } = useUndoableContext();

  const handleGoToStep = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onSelectingNode(edge[typeTargetField]);
  };

  const handleRemoveEdge = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onRemoveEdge();
  };

  return (
    <Accordion.Header as='div' bsPrefix={`itemHeader ${edge.data.type}`}>
      <div className='itemHeader__content'>
        <span>{getEdge(edge.id)?.data.label}</span>
        <ButtonGroup className='itemHeader__group'>
          <Button onClick={handleGoToStep}>
            <i className={`bx bx-skip-${typeTargetField}`}></i>
          </Button>
          <Button onClick={handleRemoveEdge}>
            <i className='bx bx-trash'></i>
          </Button>
        </ButtonGroup>
      </div>
    </Accordion.Header>
  );
}

function ItemEdge({ edge, typeTargetField, children }: ItemProps) {
  return (
    <Accordion.Item eventKey={edge.id}>
      <ItemHeader edge={edge} typeTargetField={typeTargetField} />
      <Accordion.Body>{children}</Accordion.Body>
    </Accordion.Item>
  );
}

export default ItemEdge;
