import './style.scss';

import { PanelSearchSelect } from 'modules';

import ButtonCreateScript from '../ButtonCreateScript';

const PanelSearchScripts = () => {
  return (
    <div className='panelSearchScripts'>
      <div className='panelSearchScripts__search'>
        <PanelSearchSelect
          name='name'
          label='Название скрипта'
          className='panelSearchScripts__select'
        />
        <PanelSearchSelect
          name='goal'
          label='Цель скрипта'
          className='panelSearchScripts__select'
        />
      </div>
      <ButtonCreateScript text='Добавить скрипт' />
    </div>
  );
};

export default PanelSearchScripts;
