import { useDebounce } from 'hooks';
import { useLayoutEffect, useRef, useState } from 'react';

import { СheckboxProps } from './types';

function Сheckbox({
  onChangeState,
  name,
  checked,
  updateValue,
  ...otherProps
}: СheckboxProps) {
  const [isChecked, setIsChecked] = useState(checked);
  const refInput = useRef<HTMLInputElement>(null);

  const onChange = useDebounce(() => {
    const newCheckedValue = !isChecked;

    onChangeState({ [name]: newCheckedValue });
    setIsChecked(newCheckedValue);
  }, 200);

  useLayoutEffect(() => {
    if (updateValue && refInput.current) {
      updateValue(refInput.current);
    }
  }, [updateValue]);

  return (
    <>
      <input
        {...otherProps}
        ref={refInput}
        onChange={onChange}
        checked={isChecked}
        className='form-check-input'
      ></input>
    </>
  );
}

export default Сheckbox;
