import { AxiosResponse } from 'axios';
import { ENDPOINTS } from 'constants/api_endpoints';
import { IResponse, PaginationConfig } from 'models/response';
import axiosApi from 'utils/api_helper';

import { RoleConfig } from './types';
export default class RolesService {
  static async fetchRoles(params: { query: Record<string, unknown> }) {
    const response: AxiosResponse<IResponse<PaginationConfig<RoleConfig[]>>> =
      await axiosApi.get(ENDPOINTS.ROLES_FETCH, { params: params.query });

    return response;
  }
}
