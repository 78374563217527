import { ModalDeleteContent, ModalDialog } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteUser, setModal } from 'store/users/actions';
import { getSelectedUser } from 'store/users/selectors';

const ModalDeleteUser = () => {
  const { modal, userData } = useAppSelector((state) => ({
    modal: state.users.modal,
    userData: getSelectedUser(state),
  }));

  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setModal({ type: 'none' }));
  };

  const onApply = () => {
    dispatch(deleteUser({ userId: userData.id_user }));
    // dispatch(setModal({ type: 'none' }));
  };

  return (
    <ModalDialog isOpen={modal.type === 'delete'} onClose={onClose}>
      <ModalDeleteContent
        actionName='Удалить пользователя ?'
        targetName={userData.fio}
        data={'Роль: ' + userData.role}
        onCancel={onClose}
        onApply={onApply}
      />
    </ModalDialog>
  );
};

export default ModalDeleteUser;
